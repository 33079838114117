@import './variables.scss';

.shell-container{
    background-color: $altbackground;  
}
.products-container{
    background-color: $white;
}

.main-container{
    padding: 15px 0 10px 0;
}
.category-container{
    padding-top: 20px;
}
.yotpo .yotpo-modal .yotpo-modal-header .modal-title{
    padding: 20px 40px !important;
}
/// fix for chat bot 
#fc_frame, #fc_frame.fc-widget-normal {
    bottom: 85px !important;
}
// @media screen and (max-width: 768px){
    
//     .main-container{
//         padding-top: 15px;
//     }
// }
.hp-tap-bar-title{
    text-align: center;
    font-family: $playfair;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0 0;
    margin:0;
  }

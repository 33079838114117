@import './variables.scss';

.countdown-container{
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 0;
  padding-right: 100px;
  .first-column{
    display: flex;
    align-items: center;
    text-align: right;
    padding-right: 0;
  }
  .second-column{
    padding-left: 4px;
    display: flex;
    align-items: center;
  }
  .countdown-text{
    margin-top: -4px;
  }
  
  .circular-number{
    display: inline-block;
    text-align: center;
    font-size: 14px;
    width: 34px;
    color: $cta;
    background-color: $white;
    padding: 0 5px;
    font-weight: 700;
    margin: 5px;
    .time-name{
      font-weight: 600;
      margin-top: -7px;
      font-size: 9px;
      color: $secondary;
      display: block;
    }
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .countdown-container{
    padding-right: 25%;
    .second-column{
      flex-direction: column;
      padding-top: 10px;
    }
  }
}
@media (max-width: 768px) {
  .countdown-container{
    padding-right: 0;
    .second-column{
      flex-direction: column;
      padding-top: 10px;
    }
  }
}
@media (max-width: 484px) {
  .countdown-container{
    justify-content: center;
    .first-column, .second-column{
      text-align: center;
    }
  }
}
@media (max-width: 365px) { //iphone SE
  .second-column{
    padding-top: 0 !important;
  }
}
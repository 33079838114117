@import './variables.scss';

.editor-info {
    position: absolute;
    z-index: 100;
    top: 0px;
    right: 0px;
    width: auto;
    height: 32px;
    padding: 4px 8px 8px 12px;
    background-color: $secondary;
    color: $white;
    border-left: 8px $instruction solid;
    div {
        font-weight: 800;
        display: inline;
    }
}
.edit-crop-button{
    position: absolute;
    z-index: 100;
    bottom: 14px;
    left: 14px;
    width: auto;
    font-size: 10px;
    padding: 7px 16px;
    background-color: rgba(255, 255, 255, 0.3);
    color: $secondaryGrey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .edit-icon{
        height:16px;
        width: 16px;
        margin-right: 8px;;
    }
}
.ant-slider-handle {
    border: solid 3px $instruction !important;
    &:hover {
        box-shadow: 0px 0px 0px 8px $instructionLight;
    }
}
.ant-slider-rail {
    background: $secondaryLight !important;
}
.ant-slider-track {
    background: $instruction !important;
}
.zoom-controls{
    display: flex;
    align-items: baseline;
    .zoom-action-container{
        flex: 1;
        text-align: center;   
            img{
                height: 16px;
                fill: $secondary !important;
            }
    }
    .zoom-slider{
        flex: 10;
    }
}
.editor-thumbnails-container{
    position: absolute;
    bottom: -150px;
    width: 100%;
    display: flex;
    justify-content: center;
    .editor-thumbnail{
        margin: 10px;
        width: 50px;
        height: 50px;
        img{
            width: 100%;
            height: 100%;
            min-width: 50px;
            min-height: 50px;
            margin: auto;
            object-fit: cover;
        }
        .editor-filter-tag{
            font-size: 10px;
            color: $black;
            width: 100%;
            text-align: center;
        }
    }
    .save-btn-container{
        width: 25%;
        padding: 16px;
        .save-btn{
            background: transparent;
            background-color: rgb(238, 111, 111);
            color: #fff;
            font-family: "Open Sans", sans-serif;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            border: none;
            height: 34px;
            padding: 0 15px;
            text-decoration: none;
            border-radius: 3px;
            }
    }
    .cancel-btn-container{
        width: 14%;
        padding: 17px 5px;
    }
}
@media screen and (max-width: 768px) {
    .editor-thumbnails-container{
        bottom: -80px;
    }
}

@media screen and (max-width: 769px) {
    .save-btn{
        width: 84px !important;
    }
    .cancel-btn-container{
        padding: 0px;
    }
}
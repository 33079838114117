@import './variables.scss';

.catlinks-container{
  padding: 60px 80px;
  text-align: center;
  background-color: $altbackground;
  
  .catlinks-title{
    font-family: $playfair;
    font-size: 26px;
    font-weight: 400;
  }
  .catlinks-link-container{
    font-family: $opensans;
    padding-left: 0;
    .catlinks-link{
      width: 30%;
      display: inline-block;
      padding: 10px;
      a{
        font-size: 16px;
        color: $black;
        letter-spacing: 1.5px;
      }
      a:hover{
        color: $cta;
      }
    }
  }
}
@media (max-width: 768px) {
  .catlinks-container{
    padding: 40px 20px;
    .catlinks-title{
      font-size: 22px;
    }
    .catlinks-link-container {
      padding-left: 5px;
      .catlinks-link{
        padding: 8px;
          a{
            font-size: 12px;
            letter-spacing: 0.5px;
          }
      }
    }
  }
}

@media (max-width: 490px) {
  .catlinks-container .catlinks-link-container .catlinks-link{
      width: 50%;
    }
}
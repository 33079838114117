@import '../variables.scss';

.free-preview-icon{
  height: 22px;
  margin-left: 10px;
  margin-top: 1px;
}
.all__products__page {
  .low__resolution__message {
    display: none;
  }
}

.products__page__container {
  display: flex;
  .product-column-image, .product-column-content{
    flex: none;
    padding: 0.75rem;
    
  }
  .product-column-image{
    width: 58.33333%;
  }
  .product-column-content{
    width: 41.66667%;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}
@media screen and (max-width: 768px){
  .products__page__container {
    display: block;
    .product-column-image, .product-column-content{
      padding: 0;   
    }
    .product-column-image{
      width: 100%;
    }
    .product-column-content{
      width: 100%;
    }
  }
}

.products__page__upload__btn {
  margin-top: 40px;
  display: inline-block;
  width: 120px;
  padding: 0 0 10px 0;

  &:before {
    display: block;
    //text-align: center;
    font-size: 50px;
    padding: 25px 0 15px;
  }
}
.shop-now-cta{
  font-size: 11px;
  background-color: $cta;
  color: $white;
  padding: 10px 22px;
  border: none;
  font-family: $opensans;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
}
.shop-now-cta:focus{
  outline: none;
}
.shop-now-cta:hover{
  background-color: $ctaLight;
}
.products__slider__holder {
  padding-bottom: 30px;
  display: inline-block;
  width: 100%;
}

  .loading_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
  }
  .back-to-products-container{
    margin-bottom: 10px;
    margin-left:10px;
    display: inline-block;
    line-height: 1.4;
    .back-to-products-link{
      color: $secondary;
      cursor: pointer;
      font-size: 14px;
      font-family: $opensans;
      font-weight: 400;
      padding-bottom: 0px;
      .back-to-products-text{
        user-select: none;
      }
      .back-to-products-text:hover{
        text-decoration: underline;
      }
    }
    .multiphoto-back-container{
      display: flex;
      .back-to-products-link{
        color: $secondary;
        cursor: pointer;
        font-size: 14px;
        font-family: $opensans;
        font-weight: 400;
        padding-bottom: 0px;
        user-select: none;
        .back-to-products-text:hover{
          text-decoration: underline;
        }
        .back-to-products-text{
          user-select: none !important;
        }
      }
    }
  }
.back-to-products-link:hover{
 color: $midgrey !important;
}
.cta-btn, .cta-btn-secondary {
    color: $white;
    font-family: $opensans;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border:none;
    display: block;
    width: 100%;
    height: 52px;
  }
  .cta-btn{
    background-color: $cta;
    .tab-bar-arrow-icon{
      height: 20px;
      margin-top: 5px;
      margin-left: 3px;
    }
    .tab-bar-arrow-icon-product{
      height: 18px;
      margin-bottom: 2px;
      margin-left: 5px;
    }
  }
  .cta-btn:hover{
    background-color: $ctaLight;
  }
  .cta-btn-secondary{
    background-color: $secondary;
    .tab-bar-arrow-icon{
      height: 20px;
      margin-left: 3px;
      margin-top: 2px;
    }
  }
  .cta-secondary-btn, .go-to-cart-btn {
    background-color: $secondary;
    color: $white;
    text-transform: uppercase;
    border:none;
    display: block;
  }
  .cancel-edits-btn{
    background-color: $white;
    color: $secondary;
    text-transform: capitalize;
    border:none;
    text-decoration: underline;
    margin-left: 10px;
  }
  .cta-secondary-btn, .go-to-cart-btn, .cancel-edits-btn{
    font-family: $opensans;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    height: 52px;
  }
  .cta-btn:focus, .cta-secondary-btn:focus, .cta-btn-secondary:focus, .cancel-edits-btn{
    outline:none;
  }
  
  .add-plus-icon{
    height: 14px;
    margin-right: 10px;
    vertical-align: middle !important;
    margin-bottom: 2px;
  }
  // &.open {
  //   padding-top: 20px;
  // }

.product__note {
  font-size: 12px;
  line-height: 14px;
  padding-top: 10px;

  a {
    padding-left: 5px;
    color: $secondary !important;
  }
}

.select-option{
  border: none;
  font-size: 16px; 
  font-family: $opensans;
}
.select-option:focus{
  outline:none;
}
.select-option.size{
  width: 230px !important;
  height: 35px !important;
}
.add-second-text{
  font-family: $opensans;
  font-size: 13px;
  text-align: center;
  border: solid $promo 1px;
  border-radius: 20px;
  padding: 5px 10px;
  -webkit-box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);
  -moz-box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);
  box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);
  &:hover{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .info-icon{
    height: 14px;
    margin-bottom: 3px;
    margin-left: 5px;
   }
}
.add-second-text:hover{
  cursor: pointer;
}

/* OPTION BUTTONS */
.option-button{
  font-size: 14px;
  font-family: $opensans;
  color: $secondaryGrey;
  border: none;
  border-radius: 30pt;
  height: 32px;
  text-transform: capitalize;
  margin: 5px;
  padding: 0 25px;
  background-color: #EDEFF2;
  text-decoration: none;
  white-space: nowrap;
}
.option-button:hover {
  opacity: 0.66
}
// .option-button:active, .option-button:focus{
//   border-width: 1px !important;
//   border-style: solid !important;
//   border-color: $cta !important;
//   color: $black;
// }
.option-button:focus{ 
  outline-color: $white;
}
.option-selected {
  color: $white;
  background-color: $secondaryGrey;
  text-decoration: none;
}
.option-disabled {
  opacity: 0.33;
  cursor: not-allowed !important;
}


/* OPTION BUTTONS FOR DESIGN */
.option-button.is-swatch {
  line-height: 1.2;
  padding: 8px;
  border-radius: 2px !important;
  border-color: $grey !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-size: 12px;
  color: $secondary;
  margin-right: 12px;
  margin-bottom: 8px;
  background-color: inherit;
  min-width: 87px;
  height: auto;
}
.option-button.is-swatch:hover {
  opacity: 0.66
}
.option-button.is-swatch:active, .option-button.is-swatch:focus{
  border-width: 1px !important;
  border-style: solid !important;
  border-color: $cta !important;
  color: $black;
}
.option-button.is-swatch:focus{ 
  outline-color: $white;
}
.option-selected.is-swatch {
  color: $white !important;
  // border-color: $secondary !important;
  border: none;
  background-color: $secondary !important;
  outline: 1px solid $cta;
  // outline-offset: -1px;
}
.option-disabled.is-swatch {
  opacity: 0.33;
  cursor: not-allowed !important;
}


.usp-image1, .usp-image1, .usp-image1 {
  width: 100%;
  height: auto;
}
.product__form {
  line-height: 32px;
  height: 100%; // change it to flex??
  flex-direction: column;
}
.product-form{
  padding: 20px;
  background-color: $white;
  .product__form__name {
    padding-right: 80px;
    position: relative;
    font-size: 18px;
    line-height: 18px;
    font-family: $opensans;
    font-weight: 500; 
    color: rgba(0,0,0,.85);
    margin-bottom: 9px;
    .product__form__price{
      font-family: $opensans;
      font-weight: 400;
      font-size: 20px;
    }
  }

  .product__form__price {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }

  .product-size, .product-qty{
    font-size: 12px;
    font-family: $opensans;
    font-weight: 600;
    } 
}
.product-form-description{
  padding: 30px 30px;
  background-color: $white;
  .product__form__description {
    text-align: left;
    font-size: 15px;
    line-height: 1.5 !important;
    font-family: $opensans;
    a {
      color: $secondary !important;
      font-weight: 600;
    }
    a:hover {
      text-decoration: underline;
      color: $black !important;
    }
  }
}
.product__img__preview{
  position: relative;
  .out-of-stock{
    z-index: 9;
    position: absolute;
    top:0;
    left:0;
    padding: 0.75rem;
    width: 40%;
    height: auto;
  }
  .product__img__preview2{
    position: relative;
    .new-product-img-2{
      position: absolute;
      z-index: 2;
    }
    .product-img-2{
      width: 12%;
      top:5%;
      right:3%;
    }
    .thumb-img-2{
      width: 13%;
      top:5%;
      right:4%;
    }
  }
}
.edit-photo-btn{
  color: $white;
  font-family: $opensans;
  background-color: $secondary;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  display: block;
  width: 100%;
  height: 52px;
  border-radius: 3px;
}
.edit-photo-btn:focus{
  outline: none;
}
.add-disabled{
  background-color: $ctaLight !important;
}
.add-disabled:hover{
  cursor: default !important;
}
// .product__form__description::first-line { 
//   font-size: 18px;
//   font-weight: 600;
// } 
.photos__list__container {
  padding: 0 0 20px 0;
}

.photos__list__title {
  font-size: 14px;
  font-family: $opensans;
  font-weight: 100;
  padding-bottom: 0px;
  line-height: 14px;
  padding-left: 144px;
}

.slick-slide img, .frame-2 {
  width: 100%;
  height: auto;
}

li.slick-active{
  .carousel-preview-img{
    filter: none;
    border: 1px solid $secondaryDark;
  }
  .carousel-preview-line{
    filter: none;
  }
}
.carousel-preview-line{
  height: 2px;
  width: 40px;
  background-color: $secondary;
  filter: opacity(0.5)
}
.carousel-preview-img{
  height: 34px;
  width: auto;
  filter: opacity(0.5);
}
.slick-dots{
  text-align: left;
  position: unset;
 
  li{
    width: auto;
    height: 14px;
    margin: 0 10px 0 0;
    padding: 0;
  }
}
.slick-dot-preview{
  padding-top: 0;
  position: absolute;
  bottom: -5px;
  // width: calc( 100% - 110px );
}
.slick-dots-pre-preview{
  margin-top: 5px;
}
.home-slider{
  .products__slider__holder{
    padding: 10px;
  }
}

.playVideoLink {
  position: absolute;
  bottom: -5px;
  right: 0;

  cursor: pointer;
  width: auto;
  min-width: 90px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5px;
  font-family: $opensans;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  text-align: right;

  img {
    max-width: 16px;
    margin-right: 6px;
  }
}

@media screen and (min-width: 769px) {
  .save-edits-btn{
    width: 100% !important;
    order: -1;
    margin-right: 0 !important;
    .cta-btn{
      border-radius: 3px;
    }
  }
  .cta-secondary-btn, .cta-btn{
    border-radius: 3px;
  }
  .cta-secondary-btn:hover{
    background-color: $midgrey;
  }
  .cancel-edits-btn{
    height: 30px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .back-to-products-container .zoomed-top-actions-container{
    width: 100%;
  }
  .products__slider__holder {
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    z-index: 101;
    background-color: $white;
  }
  .photos__list__title{
    display: none;
  }
  .home-slider{
    .products__slider__holder{
      padding: 5px 0;
    }
  }
  .affirm_message{
    font-weight: 700 !important;
  }
  .cancel-edits-btn{
    border: solid 1px $midgrey;
    text-decoration: none;
  }
  .products__page__container {
    .slick-prev, .slick-next{
      display: none !important;
    }
  }
  .product-column-image{
    background-color: $white;
  }
  .slick-dots{
    text-align: center;


    &.video-offset {
      padding-right: 80px;

      .carousel-preview-line {
        width: 27px;
      }
    }
  }
  .slick-dot-preview{
    bottom: 5px;
  }
  .slick-dots-pre-preview{
    margin-top: 10px;
  }
  .playVideoLink {
    bottom: 12px;
    right: 10px;
  }
  .back-to-products-container{
    display: none;
  }
  .photos__list__container-mobile{
    padding: 0;
    margin: 0 -20px;
    padding-bottom: 20px;
  }
  .multiphoto-edit{
    margin-bottom: 10px;
  }
  .product-form{
    padding: 10px 20px;

    .product__form__name {
      margin-bottom: 0.5em;
    }
  }
  .filters-div{
    height: 80px;
    width: 100%;
    background-color: $white;
  }
}

.product__img__preview{
  @media (max-width: 800px) {
    padding-bottom: 0;
  }
}
.remove-gap{
  @media (max-width: 800px) {
    padding-top: 0;
  }
}

.more-views-text{
  @media (max-width: 768px) {
  }
}
@media screen and (max-width: 768px){
  .ant-modal{
    top: 0 !important;
  }
  .cta-btn{
    width: 94%;
    margin: 8px auto;
  }
  .cta-btn-secondary{
    width: 94%;
    margin: 8px 4px;
  }
  .edit-photo-btn{
    width: 94%;
    margin: 4px 8px;
  }
  .cta-btn .tab-bar-arrow-icon{
      margin-top: 0px;
    }
  .cta-secondary-btn, .go-to-cart-btn, .cancel-edits-btn{
    width: 94%;
    margin: 8px;
  }
  .easy-btn{
    width: 80% !important;
  }
  .cta-btn, .cta-btn-secondary, .cta-secondary-btn, 
    .go-to-cart-btn, .cancel-edits-btn, .easy-btn{
      border-radius: 3px;
      box-shadow: 7px 7px 7px -3px rgba(191,191,191,1);
      -webkit-box-shadow: 7px 7px 7px -3px rgba(191,191,191,1);
      -moz-box-shadow: 7px 7px 7px -3px rgba(191,191,191,1);
    }
  .product__img__preview .out-of-stock{
      padding: 0;
    }
}
//iphone 5
@media (max-width: 320px) {
  .add-second-text{
    font-size: 12px;
  }
  .product__form__buttons .btn{
    font-size: 18px;
  }
  .option-button{
    margin-right: 7px;
  }
  .select-option.size{
    width: 200px !important;
  }
  .product-size, .product-qty{
    line-height: 1.2;
    }
}

.affirm_message{
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 13px;
  color: $black;
  margin-top: -8px;
  padding-top: 0px;
  .affirm-logo{
    height: 13px;
    margin-right: 4px;
    margin-top: inherit;
  }
}
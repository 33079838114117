@import './variables.scss';

.order-success-main-container{
    padding-top: 50px;
    text-align: center;
    padding-bottom: 120px;
    .order-success-container{
        display: flex;
        justify-content: center;
        flex-direction: column;
    .order-success-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
        }
    }
}

#coopcommerce{
  width: 50%;
  z-index: 0 !important;
  scroll-behavior: auto !important;
}

#coop-overlay{
  height: 80% !important;
  width: 78% !important;
  position: fixed !important;
  top: 29% !important;
}

.order-success-main{
  display: flex;
  width: 100%;
}

.order-success-left{
  width:  50%;
}

.order-success-check-mark{
    img{
        height: 45px;
    }
}
.order-success-page__header{
    font-family: $opensans;
    margin-top: 20px;
    font-size: 34px;
    font-weight: 600;
    color:$black;
    
}
.order-success-confirmation{
    font-family: $opensans;
    font-size: 22px;
    font-weight: 400;
    color:$black;
    padding: 0 30px;
}
.thankyou-image{
    width: 100%;
    height: auto;
    min-width: 100px;
    max-width: 200px;
    padding: 10px;
}
.order-success-page__text{
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: $opensans;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6 !important;
}

.share{
    font-size: 20px;
    padding:10px 15px;
    .share-icon{
        width: 45px;
        margin-bottom: 3px
    }
    a{
        color:$white;
        margin-right: 10px;
    }
    a:hover, 
    a:focus {
        color: $white;
        text-decoration: none;
    }
}

.facebook-share{
    background-color: #3B5998;
}
.twitter-share{
    background-color:  #1dcaff;
}

.continue-order{
    margin: 30px 0 20px 0;
    width: 100%;
    text-align: center;
}
.order-success-continue-shopping-button{
    background-color: $cta;
    font-family: $opensans;
    font-weight: 500;
    font-size: 16px;
    color: $white;
    padding: 8px 26px;
    border: none;
    text-align: center;
    border-radius: 3px;
}
.order-confirmation-main-container{
    display: flex; 
    max-width: 468px;
    width: 100%;
    margin-top: 15px;
    padding: 15px ;
    background: $white;
    .order-confirm-image-container{
    flex: 1;
    }
    .order-confirm-card-item-content-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 20px;
        .order-confirm-card-item-name{
        font-size: 12px;
        font-weight: 600;
        color: $black;
        margin-bottom: 0;
        }
        .order-confirm-button{
            img{
                height: 30px;
            }
        }
        .order-confirm-card-is-multiphoto{
            font-size: 10px;
            margin-right: 10px;
            line-height: 1.2 !important;
            color: $secondary;
        }
    }
}

@media screen and (max-width:768px){
  #coopcommerce{
    width: 100%;
    min-height: 450px;
  }
  .order-success-main-container {
    padding: 50px 0px;
  }
  .order-success-main{
    display: block;
  }
  .order-success-left{
    width:  100%;
  }
}
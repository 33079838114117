@import './variables.scss';

///  Asticles.js
.articles-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  .articles-card-container{
    display: flex;
    max-width: 800px;
    border: 1px solid $grey;
    background-color: $white;
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    .articles-card-image{
      flex: 3;
      img{
        width: 100%;
      }
    }
    .articles-card-content{
      flex: 5;
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .articles-card-title{
        font-family: $playfair;
        font-size: 20px;
      }
      .articles-card-summary{
        font-size: 12px;
        font-family: $opensans;
      }
      .articles-card-date{
        font-size: 12px;
      }
    }
  }
}

///  Asticle.js

.article-main-container{
  max-width: 800px;
  .article-content{
    display: flex;
    flex-direction: column;
    .article-title{
      font-family: $playfair;
      font-size: 24px;
    }
    .article-date{
      font-size: 12px;
    }
    .article-image{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .article-text{
      font-family: $opensans;
      padding: 30px 0; 
      p{
        color: $secondary;
        line-height: 1.5 !important;
        padding: 20px 0;
        font-size: 16px;
      }
      h3{
        font-weight: 500;
      }
      h5{
        font-size: 18px;
        font-weight: 400;
        color: $secondary;
        font-style: italic;
      }
    }
  }
  .all-articles-link-cont{
    width: 100%;
    text-align: right;
    margin-bottom: 40px;
    .all-articles-link{
      color: $secondary;
      font-family: $opensans;
      text-decoration: underline !important;
      font-size: 16px;
    }
    .all-articles-link:hover{
      color: $midgrey;
    }
  }
}

@media screen and (max-width:768px){
  .articles-main-container{
    flex-direction: row !important;
    justify-content: space-around;
    flex-wrap: wrap;
    .articles-card-container{
      flex-direction: column;
      max-width: 350px;
    }
  }
  .article-main-container{
   padding: 0 20px;
  }
}
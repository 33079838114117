@import '../variables.scss';

/// experiment Variant A --> 1x1 
.product-card-main-container-a{
  width: 47%;
  margin-bottom: 15px;
  font-family: $opensans;
  background-color: $white;
  color: $black;
  cursor: pointer;
  &:nth-child(odd) {
    margin-right: 6%;
    clear: both;
  }

  &:nth-child(even) {
    padding-left: 0;
  }
  .product-card-image-cont{
    position: relative;
    overflow: hidden;
    .new-product-img{
      position: absolute;
      z-index: 100;
      -moz-transition: all 0.4s;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
    }
    .product-img{
      width: 12%;
      top:5%;
      right:3%;
    }
    .thumb-img{
      width: 13%;
      top:6%;
      right:4%;
    }
  }
  .product-card-desc-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0 15px 15px;
    .product-card-title{
      font-family: $playfair;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
    }
    .product-card-footer-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 74px;
      .product-card-footer{
        flex:2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        .product-card-description{
          font-size: 14px;
          padding-right: 20px;
          color: $black;
        }
        .product-price-container{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          font-style: oblique;
          color: $black;
          .price-holder{
            font-size: 10px;
            text-transform: uppercase;
            margin-right: 10px;
          }
          .price{
            font-weight: 600;
            font-size: 16px;
          }
        }
        
      }
      .product-card-btn-container{
        flex:1;
        text-align: right;
        margin-top: auto;
        margin-bottom: 5px;
      }
    }
  }
  &:hover {
    .product-card-desc-container{
      .product-card-title{
        text-decoration: underline;
        color: $secondaryDark;
      }
    }
    .product-card-image-cont{
      border: solid $secondary 1px;
      .new-product-img{
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
      }
      .product-card-footer-container{
        .product-card-btn-container{
          .shop-now-cta {
            background-color: $ctaLight;
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px){
  .product-card-main-container-a{
    width: 100%;
    margin-bottom: 0;
    &:nth-child(odd) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width:768px){
  .product-card-main-container-a{
    margin-bottom: 0;
    .product-card-desc-container{
      padding: 15px;
      .product-card-title{
        font-size: 18px;
      }
      .product-card-footer-container{
        .product-card-footer{
          flex:2;
          .product-card-description{
            font-size: 12px;
          }
          .product-price-container{
            .price{
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}              
@import './variables.scss';

.guarantee-container{
  background-color: $white;
  width: 100%;
  padding:15px 10px !important;
  display: flex;
  justify-content: center;
  border-top: solid 1px $grey;
  border-bottom: solid 1px $grey;
  justify-items: center !important;
  .quality-text, .shipping-text{
    display: flex;
    justify-items: baseline;
    font-family: $opensans;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    cursor: pointer;
    p{
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 5px;
    }
    .underline{
      text-decoration: underline;
    }
    .guarantee-icon{
      height: 26px;
      padding-right: 8px;
      padding-left: 10px;
    }
  }
  .quality-text {
    padding-right: 30px;
    }
  .ribbon-subtext{
    text-transform: lowercase;
    letter-spacing: normal;
    font-size: 10px;
  }
}

@media (min-width:769px ){
  .guarantee-container{
    padding: 0.5vw;
    flex-wrap: wrap;
    .tight-ribbon{
      font-size: 12px; //in case wv doesnt work;
      font-family: $opensans;
      color: $white;
      font-weight: 600;
      margin-bottom: 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      .guarantee-icon{
        height: 20px;
        padding-right: 4px;
      }
    }
  } 
} 

@media (min-width:1408px ){ // wide screen
  .guarantee-container{
    padding: 8px;
    .tight-ribbon{
      font-size: 12px;
    }
  }
}
@media (min-width:769px) and (max-width:890px){
  .guarantee-container{
    .tight-ribbon .ribbon-subtext{
      display: block;
      text-align: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .guarantee-container{
    padding: 8px; //in case wv doesnt work
    padding: 1vw;
    display: block !important;
    border-bottom: none;
    padding-bottom: 0;
    .ribbon-item{
      width: 100%;
      margin-left: 0;
      padding-bottom: 10px;
      margin-bottom: 8px !important;
      border-bottom: 1px solid $altbackgrounddark;
      justify-content: center;
      align-items: center;
    } 
    .affirm{
      border-bottom: none;
      margin-bottom: 0;
    }
    .image-container{
      width: 80px;
      text-align: right;
    }
  }

  .shipping-text, .tightRibbon{
    text-align: center;
    font-family: $opensans;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1.5px;
    p{
      text-align: left;
      font-size: 12px;
      margin-top: 0;
      width: 250px;
      margin-left: 25px;
    }
    .guarantee-icon{
      height:24px !important;
      margin-top: 0;
    }
  }
    
  .shipping-text .guarantee-icon, .tightRibbon .guarantee-icon{
    height: 20px; //in case wv doesnt work
    padding-left: 10px;
    padding-right: 6px;
  }
  .fix-ribbon{
    font-size: 11px;
  }
  .fix-ribbon .guarantee-icon{
    height: 20px;
  }
}

.guarantee-white-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color:$white;
  padding: 20px 8px 10px;
  .guarantee-cont, .shipping-cont{
    display: flex;
    flex-direction: row;
    .guarantee-icon-cont{
      .guarantee-icon {
        margin-top: 6px;
        height: 14px;
        margin-right: 8px;
      }
    }
    .guarantee-text{
      font-family: $opensans;
      letter-spacing: .5px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      .underline{
        text-decoration: underline;
      }
    }
    .guarantee-text{
      cursor: pointer;
    }
  }
  .guarantee-cont{
  padding-right: 10px;
  }
}

.guarantee-top-border{
  border-top: solid 1px $grey;
}

.guarantee-top-bottom-border{
  border-top: solid 1px $grey;
  border-bottom: solid 1px $grey
}
@media screen and (max-width: 458px) {
  .guarantee-container{
    .fix-ribbon{
      font-size: 10px; //in case wv doesnt work
      font-size: 2.2vw;
    }
    .shipping-text, .tightRibbon{
      font-size: 12px; //in case wv doesnt work
      font-size: 2.8vw;
    }
  }
  .fix-ribbon .guarantee-icon{
    height: 4vw !important;
  }

  .guarantee-white-container{
    padding: 10px 10px 4px;
  }
}

.ribbon-item{
  margin-left: 10px
}

.affirm-logo{
  height: 20px !important;
  margin-bottom: 5px;
}
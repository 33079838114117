@import './variables.scss';

.product-carousel-container{
  padding: 30px 70px;
  .carousel-title{
    text-align: center;
    h2{
      color: $secondaryDark;
      font-family: $playfair;
      font-size: 26px;
    }
  }
  .slick-prev, .slick-next {
    padding: 0;
    background: transparent;
    opacity: 0.5;
    height: 60px;
    width: 50px;
    top: 38%;
  }
  .slick-next {
    right: -80px;
  }
  .slick-prev{
    left: -80px;
  }
  .product-card-main-container-a {
    width: 100%;
    padding: 20px;
    margin: 0;
    .product-card-desc-container{
      text-align: center;
      height: 80px;
      .product-card-title{
        font-size: 16px;
      }
      .product-card-footer-container .product-card-footer .product-price-container{
        width: 100%;
        justify-content: center;
      }
      
    }
  }
  .standalone-bottomline{ // yotpo
    display: flex;
    justify-content: center;
  }
}
    

@media screen and (max-width:768px){
  .product-carousel-container{
    padding: 20px 0;
    // .slick-slider .slick-track{
    //   left: 25vw;
    // }
    .slick-prev, .slick-next{
      display: block !important;
      height: 40px;
      width: 30px;
      top: unset;
      bottom: 10px;
      opacity: 0.5;
    }
    .slick-next {
      right: 5px;
    }
    .slick-prev{
      left: 5px;
    }
  }
}
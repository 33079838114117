@import './variables.scss';
.cm-main-container{
    text-align: center;
    .cm-contaier-2{
        padding: 30px;
        position: relative;
        background-color: $altbackgrounddark;
        .love-heading{
            font-family: $opensans;
            font-size: 24px;
            font-weight: 400;
            padding-bottom: 20px;
        }
        .cm-text{
            font-family: $opensans;
            font-size: 14px;
            padding: 0 25%;
        }
        .cm-ornament1{
            position: absolute;
            top: 180px;
            left: 60px;
            width: 270px;
        }
        .cm-ornament2{
            position: absolute;
            top: 80px;
            right: 85px;
            width: 180px;
        }
    }
    .cm-contaier-3{
        padding: 30px 0;
    }
    .all-products-btn-container{
        padding-top: 50px;
        padding-bottom: 30px;
        .all-products-btn{
            color: white;
            background-color: $cta;
            font-size: 20px;
            font-family: $opensans;
            font-weight: 400;
            padding: 12px 42px;
            border-radius: 2px;
        }
    }
}
@media screen and (max-width:768px){
    .cm-main-container{
        .cm-contaier-2{
            padding: 20px;
            .cm-text{
                padding: 0 5%;
            }
        } 
        .cm-contaier-3{
            padding: 0;
        } 
    }  
}
@import '../../../assets/stylesheets/variables.scss';

.modal-container{
    padding: 30px;
}
.ReactModal__Content{
    width: 100vw;
    padding:0 !important;
    .modal-close-btn{
        margin-right: 15px;
        position: sticky;
        right: 0;
        opacity: 0.6;
        img{
            height: 15px !important;
        }
    }
    .modal-close-btn:hover{
        opacity: 1;
    }
}
.ant-drawer-body {
    padding: 0 !important;
    .ant-drawer-close{
        .anticon{
            font-size: 30px !important;
        }
    }
}
.upload-add-button-container{
    width: 100%;
    position: sticky;
    bottom: 10px;
    text-align: center;
    .upload-add-button{
        width: 90% !important;
        margin: auto;
    }
}
.upload-header{
    height: 50px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    .uploader-back-btn{
        background-color: transparent;
        border: none;
        font-family: $opensans;
        font-size: 14px;
        &:focus{
            outline: none;
        }
        .arrow-back{
            height: 20px;
            margin-right: 10px;
            filter: brightness(0.8)
        }
    }
}
.upload-container{
    padding: 20px 0 30px 30px;
    background-color: $white;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .add-photos-container{
        max-width: 330px;
        margin-left: 30px;
        margin-bottom: 30px;
    }
    .add-photos-title{
        padding-top: 20px;
        font-size: 14px;
        font-family: $opensans;
        color: $black;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .add-photos-title-2{
        padding-top: 20px;
        font-size: 14px;
        font-family: $opensans;
        color: $black;
        font-weight: 600;
        margin-bottom: 20px;
        border-top: 1px solid $altbackgrounddark;
        margin-right: 25px;
        margin-bottom:10px;
        padding-left: 30px;
    }
    .upload-scroll{
        overflow-x: auto;
        overflow-y: visible;
        position: relative;
        margin-left: 30px;
        .upload-images-container{
            margin: 0 0 10px;
            display: flex;
            width: 100%;
            transition: all 500ms ease-in-out;
            .images-container-flex{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
            }
            .img-container{
                margin: 10px;
                width: 126px;
                height: 74px;
                display: flex;
                align-items: center;
                overflow: hidden;
                background-color: $altbackgrounddark;
                .upload-image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 33%;
                }
            }
        }
    }
    .upload-buttons-columns{
        margin: 0 0 10px 0;
        //max-width: 330px;
        .upload-buttons-title{
            font-family: $opensans;
            color: $black;
            padding-bottom: 30px;
            text-align: center;
            border-bottom: 1px solid $altbackgrounddark;
            margin-right: 25px;
            .upload-title{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 12px;
            }
            .upload-subtitle{
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        .uploader__item{
            display: flex;
            justify-content: space-between;
            .dropzone-area{
                width: 160px;
                border-radius: 5px;
                //filestack dropzone
                height: 53px;
                margin-top: -1px;
                position: relative;
                .fsp-drop-pane__container {
                    border: none;
                    background-color: none;
                    margin: 1px;
                    margin-right: 10px;
                    width: 160px;
                    position: relative;
                    cursor: pointer;
                    .fsp-drop-pane__drop-zone {
                        border: none
                    }
                    .fsp-drop-pane__icon {
                        margin-top: -30px;
                        height: 25px;
                    }
                    .fsp-drop-pane__text {
                        font-family: $opensans;
                        color: $black;
                        font-size: 16px;
                        height: 20%;
                        margin-top:5px;
                        margin-bottom: 25px;
                    }
                }
            }
        }

        .button-upload{ // all upload buttons styling
            width: 160px;
            height: 53px;
            border: 1px solid $midgrey;
            padding: 8px;
            border-radius: 5px;
            font-size: 12px;
            text-transform: uppercase;
            background-color: $white;
            align-items: center;
            display: flex;
            cursor: pointer;
            top: 0;
            .icon-container{
                height: 30px;
                width: 50px;
                img{
                    height: 30px;
                }
                .instagram-icon{
                    height: 30px;
                }
            }
            .upload-btn-text{
                color: $black;
                text-transform: capitalize;
                font-family: $opensans;
                text-align: center; 
                width: 100%;
            }
        }
        .inside-dropzone{
            position: absolute;
            z-index: 99;
        }
        .button-upload:focus {
            outline: none;
        }
        .button-upload:hover, .dropzone-area:hover {
            box-shadow: 0px 3px 6px #00000029;
            -webkit-box-shadow: 0px 3px 6px #00000029;
            -moz-box-shadow: 0px 3px 6px #00000029;
        }
    }
    .upload-buttons-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }
    .upload-footer{
        width: 100%;
        font-size: 12px;
        padding-right: 20px;
        text-align: center;
        .need-help-link{
            text-decoration: underline !important;
            color: $secondary;
        }
        .secure-text{
            color: $secondary;
            margin-bottom: 5px;
        }
    }
   
    // Collapse 
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content,
    .ant-collapse-borderless > .ant-collapse-item{
        background-color: $white !important;
    }
    .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
        padding: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        width: 300px;
        padding-left: 0px !important;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        right: -10px;
    }
}
.upload-container .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
    .no-image{
        margin: 20px 40px;
    }
}

#upload-text{
    height: 200px;
    line-height: 1.5 !important;
}



.button-upload:hover,
.button-upload:active,
.button-upload:focus {
    color: $mediaHover;
}
.button-facebook{
    background-color:$facebook;
    color: $facebookLight;
}

.spin-container{
    text-align: center;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}
.upload-check{
        position: fixed;
        z-index: 999;
        height: 100px;
        width: 100px;
        overflow: show;
        margin: auto;
        top: 20vh;
        left: 0;
        bottom: 0;
        right: 0;
}
.upload-spinner{
    
    .ant-spin-dot{
        font-size: 100px;
        color: $grey;
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 25vh;
        left: 0;
        bottom: 0;
        right: 0;
    }
}


////////
.photo-upload-header{
    position: sticky;
    z-index: 3;
    top: 0;
    background-color: $altbackgrounddark ;
    padding: 20px 80px 10px;
    margin: -20px -20px 10px;
}
//filestack dropzone
#mobile-filestack {
    position: relative;
    width: 100%;
    .full-width-upload-btn{
        width: 100%;
    }
    .fsp-drop-pane__container{
        position: absolute;
        top: 0;
        opacity: 0;
        width: 100%;
    }
}

@media (max-width: 768px){
    .upload-container {
        padding: 10px 0 30px 25px;
        .add-photos-container{
            margin-left: 0;
        }
    }
    .add-photos-title-2{
        padding-left: 0px !important;
    }
    .upload-scroll{
        margin-left: 0px !important;
    }
    .ReactModal__Content{
        width: 100vw;
    }
    .photo__upload__list__holder {
        padding: 0 !important;
    }
    .ant-drawer-body{
        padding: 0 !important;
    }
}
.fsp-drop-pane__container {
    border: none !important;
    height: 53px !important;
    position: relative;
    cursor: pointer;
    .fsp-drop-pane__icon {
        margin-top: 0;
        height: 0;
        background-image: none
    }
    .fsp-drop-pane__text {
        display: none;
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}


.photo__upload__list__holder{
    padding:0 30px;
}
.photo-upload-container.has-text-centered{
    text-align: center;
}

.photos__action__limit {
    
    .button-upload-images, .button-disabled{
        background-color: $cta;
        color: $white;
        font-family: $opensans;
        font-size: 18px;
        padding: 7px 15px;
        text-transform: uppercase;
        border: none;
        border-radius: 2px;
        margin-bottom: 20px;
    }
    .button-disabled{
        opacity: 0.5;  
    }
}

.check-mark-icon{
        position: relative;
        z-index: 2;
        left: 80% !important;
        top: 70% !important;
        width: 40px !important;
        height: auto !important;     

}
.check-mark-opacity{
    z-index: 20;
    width: 100px!important;
    height: 100px!important;
    
}
// FacebookImport.js

.photo__upload__select{
    -webkit-appearance: none; 
    -moz-appearance: none;
    width: 200px;
    appearance: none !important;
    border-radius: 0px;
    border: none;
    font-family: $opensans;
    color: $midgrey;
    height: 30px !important;
    line-height: 2;
    padding-left: 10px;
    background: url('https://storage.googleapis.com/myphoto-img/icons/arrows-up-and-down.png') no-repeat;
    background-position: right center;
    background-color: $white;
}

.photo__upload__select:focus{
    outline: none;
}

.photos__upload__btns__holder{
    text-transform: uppercase ;
    font-size: 16px;
    font-family: $opensans;
    color: $secondary;
    font-weight: 400;
    border-bottom: 0 solid;
    text-decoration: underline;
    display: inline-block;
    line-height: 1.5;
    cursor: pointer;
    margin: 30px auto;
}

  //from phone{
.photo-upload-title{
    text-transform: initial;
    line-height: 1.5 !important;
    font-weight: 500;
    margin-top: 10px;
}
.phone-upload-container{
    padding: 0 15px;
    .phone-steps{
        font-family: $opensans;
        .phone-import-title{
            font-size: 18px;
        }
        .phone-import-text{
            font-size: 14px;
        }
        .paragraph{
            margin-bottom: 4px;
        }
        .end-paragraph{
            margin-bottom: 25px;
        }
        .phone-import-subtext{
            font-size: 12px;
            margin-left: 80px;
            font-weight: 600;
        }
        .phone-import-image-container{
            padding: 10px 0 5px;
            display: flex;
            justify-content: center;
            .phone-import-line{
                border-bottom: 1px solid #c8c8c8;
                height: 20px;
                width: 100px;
                margin: 0 10px;
            }
            .phone-import-image{
                height: 40px;
            }
        }
    }
    .ant-form-item-label{
        text-align: left;
    }
            .ant-form label{
                font-size: 17px;
                color: $secondary;
            }
    
}
.button-upload-phone-container{
    text-align: center;
    .button-upload-phone{
        background-color: $cta;
        color: $white;
        font-family: $opensans;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 26px;
        text-transform: uppercase;
        border: none;
        border-radius: 2px;
        margin: 20px 0 10px;
    }
    .button-upload-phone:focus{
        outline: none;
    }
}

.facebook-text{
    font-size: 16px;
    font-family: $opensans;
    line-height: 2 !important;
    margin-bottom: 20px;
    }

    @media (max-width: 370px){
        .upload-container .upload-buttons-columns .button-upload{ 
            width: 124px;
            height: 110px;
        } 
    }
@import './variables.scss';

.how-main-container{
  margin-top: -22px;
  margin-bottom: -10px;
  .how-header-container{
    position: relative;
    .how-header-img{
      width: 100%;
    }
    .how-header-div{
      background-color: #c2bba5;
      width: 100%;
      height: 300px;
    }
    .how-title-content{
      position: absolute;
      top: 0;
      padding: 20px 32%;
      text-align: center;
      font-family: $opensans;
      .how-title1, .how-title2{
        font-size: 22px;
        text-transform: uppercase;
        letter-spacing: 4px;
      }
      .how-header-text{
        font-size: 14px;
      }
    }
  }
  .tab-bar-arrow-icon{
    height: 20px;
    padding-left: 3px;
  }
  .how-subtitle-container{
    background: $white;
    padding: 30px 0 60px 0;
    text-align: center;
    font-family: $opensans;
    text-transform: uppercase;
    color: $black;
    .how-subtittle-text1{
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: 700;
    }
    .how-subtittle-text2{
      font-size: 20px;
      letter-spacing: 3px;
      margin-bottom: 50px;
      .italic{
        font-style: italic; 
      }
    }
  }
  .how-cards-container{
    background: $altbackground;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
    .how-card{
      margin: -30px 20px 0;
      background: $white;
      max-width: 430px;
      .how-card-img{
        width: 100%;
      }
      .how-card-text-container{
        padding: 40px 70px;
        font-family: $opensans;
        color: $black;
        .how-card-title{
          text-transform: uppercase;
          font-size: 16px;
          text-align: center;
          letter-spacing: 3px;
          font-weight: 600;
        }
        .how-card-text{
          font-size: 14px;
          .italic{
            font-style: italic;
            text-indent: 20px;
            &::before {
              content: "\A";
              white-space: pre;
            }
          }
          .italic-small{
            font-size: 12px;
          }
        }
      }
    }
  }
  .how-crazy-physical-container{
    .how-crazy-container{
      text-align: center;
      background-color: $white;
      padding: 80px 60px 40px;
      font-family: $opensans;
      color: $black;
      .how-crazy-title1{
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 2px;
        font-weight: 700;
      }
      .how-crazy-title2{
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 3px;
        margin-bottom: 50px;
      }
      .how-crazy-cards-container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .how-crazy-card{
          display: flex;
          flex-direction: column;
          padding: 10px 30px;
          width: 300px;
          .how-crazy-card-icon{
            height: 30px;
          }
          .how-crazy-card-title{
            text-transform: uppercase;
            font-weight:700;
            padding-top: 15px;
            letter-spacing: 2px;
          }
          .how-crazy-card-text{
  
          }
        }
      }
    }
  }
  .how-more-container{
    background-color:$midgrey;
    padding: 40px 15px;
    text-align: center;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    .how-more-title{
      font-size: 14px;
    }
    .how-more-cards-container{
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .how-more-card{
        display: flex;
        flex-direction: column;
        padding: 10px;
        .how-more-image{
          max-width: 150px;
        }
        .how-more-text{
          font-size: 14px;
          padding-top: 15px;
          color: $white;
        }
        &:hover{
          .how-image-container{
            background-color: $cta;
            .how-more-image{
                opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px){

  .how-main-container{
    .how-header-container {
      .how-header-div{
        height: 250px;
      }
      .how-title-content{
        padding: 10%;
      }
    }
    
    .how-crazy-physical-container{
      .how-crazy-container .how-crazy-title2{
        margin-bottom: 20px;
      }
      .how-physical-container{
        width: 100%;
        margin: 0;
      }
    }
      
  }
}
.how-cta-btn-container{
  width: 100%;
  text-align: center;
  .how-cta-btn{
    color: $white;
    background-color: $cta;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $opensans;
    padding: 8px 26px;
    font-size: 14x;
    border: none;
    border-radius: 3px;
    margin: 8px;
  }
  .how-cta-btn:hover{
    background-color: $ctaLight;
  }
}
.how-cta-btn-tabbar{
  padding: 0;
  height: 52px;
  width: 100%;
  font-size: 16px;
  .tab-bar-arrow-icon{
    height: 20px;
    padding-left: 3px;
  }
}
//iphone 5
@media (max-width: 320px) {
  .how-main-container{
    .how-header-container .how-title-content{
      padding: 9%;
    }
  }
}
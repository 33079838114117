@import './variables.scss';

.preview-main-cont{
  .preview-header-container{
    padding: 40px 30px 35px;
    text-align: center;
    background-color: $secondary;
    .preview-title{
      font-family: $playfair;
      color: $white;
      font-size: 24px;
      margin-bottom: 5px;
    }
    .preview-product-name{
      font-family: $playfair;
      color: $white;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .preview-text{
      font-family: $opensans;
      color: $white;
      font-size: 16px;
      line-height: 1.2 !important;
      margin-bottom: 0;
      .second-paragraph{
        display: block;
      }
    }
  }
  .preview-content{
    padding: 10px;
    background-color: $secondaryDark;
    overflow-y: scroll;
    min-height: 320px;
    //max-height: 400px;
    .preview-images-container{
      display: flex;
      justify-content: center;
      width: 600px;
      flex-wrap: wrap;
      margin: auto;
      .preview-image{
        outline: 1px solid #fff;
        width: 220px;
        margin: 15px;
        position: relative;
      }
      .preview-shape-image-cont{
        width: 220px;
        margin: 15px;
        position: relative;
        .preview-shape-image{
          position: absolute;
          width: 220px;
          z-index: 20;
        }
      }
    }
    .preview-ctas{
      display: flex;
      justify-content: center;
      align-items: baseline;
      padding: 20px;
      .preview-back-btn, .preview-approve-btn{
        font-family: $opensans;
        font-size: 14px;
        padding: 8px;
        text-transform: uppercase;
        border: none;
        width: 150px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
      }
      .preview-back-btn{
        background-color: $white;
        color: $secondary;
        margin-right: 10px;
        font-weight: 600;
      }
      .preview-back-btn:hover{
        color: $secondaryLight;
      }
      .preview-approve-btn{
        color: $white;
        display: block;
        background-color: $cta;
        margin-left: 10px;
      }
      .preview-approve-btn:hover{
        background-color: $ctaLight;
      }
    }
  }
}

@media screen and (max-width:768px){
  .preview-main-cont {
    .preview-header-container .preview-text {
      font-size: 14px;
      .second-paragraph{
        display: inline;
      }
    }
    .preview-content .preview-images-container {
    width: auto;
    }
  }
}
@import '../variables.scss';

.more__views {
  overflow: hidden;
  clear: both;
  padding: 75px 0 40px;

  h3 {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 15px;
  }
}

.more__views__list {
  overflow: hidden;
}

.more__views__item {
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding-bottom: 20px;

  &:nth-child(odd) {
    padding-right: 10px;
  }

  &:nth-child(even) {
    padding-left: 10px;
  }

  img {
    width: 100%;
  }
}

// @media (max-width: $tablet-width) {
//   .more__views {
//     padding-top: 20px;
//   }
// }

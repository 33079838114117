@import './variables.scss';
.cta-container{
  background-color: $altbackground;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  .cta-text-container{
    flex:5;
    width: 100%;
    text-align: right;
    margin-right: 30px;
    h2{
      color: $secondaryDark;
      font-family: $playfair;
      font-size: 26px;
      line-height: 1.2 !important;
      margin-bottom: 0;
    }
  }
  .cta-button-container{
    flex: 3;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .cta-container{
    flex-direction: column;
    .cta-text-container{
      padding-bottom: 15px;
      text-align: center;
      h2{
        font-size: 16px;
      }
    }
    .cta-button-container{
      width: 100%;
      text-align: center;
    }
  }
}
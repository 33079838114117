
// COLUMNS:

.columns{
  display: flex;
}
.columns.with-padding-top-bottom{
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.columns.with-padding-sides{
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.column.is-2 { // used in: footer & checkout
  flex: none;
  width: 16.66667%;
}
.column.is-4 { // used in: footer & checkout
  flex: none;
  width: 33.33333%;
}
.column.is-6 { // used in: checkout
  flex: none;
  width: 50%;
}
.column.is-8 { // used in: checkout
  flex: none;
  width: 66.66667%;
}
.semi-bold{
  font-weight: 600;
}
.bold{
  font-weight: 700;
}
.italic{
  font-style: italic;
}
.underlined{
  text-decoration: underline;
}

// TAB BAR:

.tab-bar-level{
  align-items: center;
  justify-content: space-between;
  display: flex;
  .tab-bar-level-item{
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 769px){
  .tab-bar-level{
    .tab-bar-level-item:last-child{
      margin-left: 20px;
    }
  }
}// end of tab bar

/// HIDE depending on screen size:

@media screen and (min-width: 1024px){  
  .is-hidden-desktop {
      display: none !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important;
  }
}

@media screen and (max-width: 1023px){
  .is-hidden-touch {
      display: none !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}
@media screen and (min-width: 769px){
.is-hidden-tablet {
    display: none !important;
  }
}
@media screen and (max-width: 768px){
  .is-hidden-mobile{
    display: none !important;
  } 
}

///(CONTAINER) site responsivness depending on screen size:

@media screen and (min-width: 1088px){
  .container {
      margin: 0 auto;
      max-width: 960px;
      width: 960px;
  }
}
@media screen and (min-width: 1472px){
  .container {
      margin: 0 auto;
      max-width: 1344px;
      width: 1344px;
  }
}




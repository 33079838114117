@import './variables.scss';

.shipping-modal-container{
  .shipping-main-container{
    padding: 50px 10% 40px;
    font-family: $opensans;
    text-align: center;
    .shipping-image{
      width: 80px;
      margin-bottom: 20px;
    }
    .shipping-title{
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 26px;
      color: $black;
    }
    .shipping-subtitle{
      color: $cta;
      font-size: 18px;
      font-weight: 700;
    }  
  }
  .shipping-guarantee-container{
    padding: 20px 10% 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .shipping-column{
      padding: 20px;
      border-bottom: 1px solid $grey;
      display: flex;
      justify-content: space-around;
      &:first-child {
        border-top: 1px solid $grey;
      }
      .shipping-column-img{
        flex: 1;
        text-align: right;
        margin-right: 20px;
        img{
          height: 35px;
        }
      }
      .shipping-column-title-cont{
        flex: 2;
        text-align: left;
        .shipping-column-title{
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 0;
        }
        .shipping-column-text{
          font-size: 16px;
        }
      }
      .shipping-column-type{
        font-size: 16px;
        flex: 2;
      }
      .shipping-column-price{
        flex:1;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

  }
  .shipping-footer-container{
    padding: 30px 20%;
    background-color: $altbackground;
    width: 100%;
    border-top: 1px solid $grey;
    text-align: center;
    display: flex;
    justify-content: space-between;
    .shipping-bottom-image{
      width: 120px;
      margin-right: 80px;
    }
    .shipping-footer-text{
      font-size: 16px;
      line-height: 1.5 !important;
      text-align: left;
    }
  }
}

@media screen and (max-width:768px){
  .shipping-modal-container{
    .shipping-main-container{
      padding: 40px 3% 40px;
      .shipping-title{
        font-size: 22px;
        letter-spacing: 6px;
      }
      .shipping-subtitle{
        font-size: 16px;
      }  
    }
    .shipping-guarantee-container{
      padding: 10px 3% 60px;
      .shipping-column{
        padding: 20px 10px;
        .shipping-column-img{
          margin-right: 10px;
          img{
            height: 30px;
          }
        }
        .shipping-column-title-cont{
          .shipping-column-title{
            font-size: 14px;
          }
          .shipping-column-text{
            font-size: 14px;
          }
        }
        .shipping-column-type{
          font-size: 14px;
        }
        .shipping-column-price{
          font-size: 14px;
        }
      }
    }
    .shipping-footer-container{
      padding: 40px 5%;
      flex-direction: column;
      .shipping-bottom-image{
        width: 100px;
        margin-bottom: 30px;
      }
    }
  }
}
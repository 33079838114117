@import '../variables.scss';

.product_content {
  width: 100%;
  height: 100%;

  img {
      max-width: 100%;
  }

  strong {
    font-weight: 700;
  }
}

.products__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
}

// old dproduct cards styling:::: 
// to DELETE when <ProductCard /> implememnted everywhere
.products__list__item {
  text-align: left;
  box-sizing: border-box;
  width: 47%;
  float: left;
  margin-bottom: 40px;
  font-family: $opensans;
  background-color: $white;

  &:nth-child(odd) {
    margin-right: 6%;
    clear: both;
  }

  &:nth-child(even) {
    padding-left: 0;
  }

  img {
    width: 100%;
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
    padding-top: 15px;
    font-family: $opensans;
  }

  p {
    font-size: 14px;
    color: $black;
    font-family: $opensans;
    

    &::first-letter {
      text-transform: capitalize;
    }
  }

  a {
    vertical-align: baseline;
    color: $secondary !important;
  }

  .tag {
    padding: 5px 0 0;
    font-size: 14px;
  }

  .price__holder {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .price {
    font-size: 18px;
    display: block;
    padding-top: 20px;
    font-weight: 700;
  }

  .products__list__link {
    width: 100%;

    .thumbnail-generator {
      position: relative;
      min-height: 100px;
      display: block;
    }

    .loading_image {
      width: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.print-parent{
  .zoomed-actions-container{
    position: relative;
    .zoomed-actions{
      position: absolute;
      bottom: 50%;
      z-index: 100;
      width: 100%;
      .flex-zoomed-actions{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .zoomed-previous, .zoomed-next{
          margin: 0 10px;
          background: $secondary ;
          border-radius: 50%;
          opacity: 0.7;
          height: 40px;
          width: 40px;
          cursor: pointer;
          .zoomed-ation-img-prev{
            padding: 7px 9px 7px 6px;
            width: 100%;
          }
          .zoomed-ation-img-next{
            padding: 7px 6px 7px 9px;
            width: 100%;
          }
        }
      }
      .zoomed-upload-div{
        width: 60%;
        height: 40px;
      }
      .clickable {
        cursor: pointer;
      }
    }
  }
}
.product-desc-container{
  padding: 0 10px;
}
.home-product-image-cont{
  position: relative;
  .new-product-img{
    position: absolute;
    z-index: 2;
  }
  .product-img{
    width: 12%;
    top:5%;
    right:3%;
  }
  .thumb-img{
    width: 13%;
    top:6%;
    right:4%;
  }
}

.products__header {
  padding-bottom: 0;
  
  h1 {
    padding: 0 0 20px;
  }

  p {
    padding-bottom: 15px;
  }
}

@media (max-width: $mobile-width) {
  .products__list__item {
    float: none;
    width: 100%;
    margin-bottom: 10px;

    &:nth-child(odd) {
      padding-right: 0;
      margin-right: 0;
    }

    &:nth-child(even) {
      padding-left: 0;
    }
  }
}
.price-container{
  padding-top: 0 !important;
  .price__holder{
    margin-right: 10px;
  }
  .price{
    padding-bottom: 10px;
  }
}
.shop-now{
  font-family: $opensans !important;
  text-decoration: underline;
  color:$black;
}


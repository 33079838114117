@import './variables.scss';

.unitedway-container{
  .unitedway-hero{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .unitedway-hero-cont{
      //margin: 40px auto 60px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .unitedway-hero-title{
        font-size: 36px;
        font-family: $opensans;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .unitedway-hero-sibtitle{
        font-size: 16px;
        font-family: $opensans;
        font-weight: 400;
      }
    }
  }

  .unitedway-banner{
    display: flex;
    .unitedway-banner-cont{
      background-color: $white;
      padding-left: 150px;
      flex: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .unitedway-banner-sibtitle1{
        font-family: $opensans;
        font-size: 16px;
        font-weight: 400;
      }
      .unitedway-banner-title{
        font-family: $opensans;
        font-size: 46px;
        font-weight: 700;
        text-transform: uppercase;
      }
      .unitedway-banner-sibtitle2{
        font-family: $opensans;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .unitedway-banner-image{
      flex: 4;
      background-color: #D2BB91;
      text-align: right;
      padding: 20px 0 20px 20px;
      img{
        padding: 20px 0 10px auto;
        max-width: 400px;
      }
    }
  }

  .unitedway-content{
    .unitedway-content-cont{
      margin-left: 5vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 450px;
      .unitedway-promo-sibtitle1{
        font-family: $opensans;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
  
      }
      .unitedway-promo-title-cont{
        display: flex;
        .promo-text-cont{
          margin-right: 40px;
        }
      }
      .unitedway-promo-title{
        font-family: $opensans;
        font-size: 36px;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 1.2;
      }
      .unitedway-promo-sibtitle2{
        font-family: $opensans;
        font-size: 14px;
        font-weight: 400;
        margin-top: 40px;
      }
      .unitedway-promo-button{
        border-radius: 40px;
        background: #518EC5;
        width: 320px;
        height: 50px;
        color: $white;
        font-size: 16px;
        padding: 14px 30px;
        text-align: center;
      }
      .unitedway-promo-sibtitle3{
        margin-top: 20px;
        font-family: $opensans;
        font-size: 14px;
        font-weight: 400;
      }
      .unitedway-promo-image{
        height: 75px;
      }
    }
    }
  .info-color{
    color: #D2BB91;
    font-weight: 700;
  }
}
@media (min-width: 769px){
  .unitedway-container .unitedway-content .unitedway-content-cont .image-mobile{
    display: none;
  }
}
@media (max-width: 768px) {
  .unitedway-container{
    .unitedway-hero {
      height: 200px !important;
      background-image: none !important;
      .unitedway-hero-cont{
        .unitedway-hero-title{
          font-size: 22px;
        }
      }
    }
    .unitedway-banner{
      flex-direction: column;
      .unitedway-banner-cont{
        padding: 50px 10%;
        .unitedway-banner-title{
          font-size: 32px;
        }
      }
      .unitedway-banner-image{
        img{
          width: 350px ;
        }
      }
    }
    .unitedway-content{
      .unitedway-content-cont{
        margin: 0;
        padding: 50px 10%;
        .unitedway-promo-image{
          height: 45px;
          margin-left: 15px;
        }
        .unitedway-promo-title-cont{
          .promo-text-cont{
            margin-right: 0;
          }
          .image-desktop{
            display: none;
          }
        }
        .unitedway-promo-button{
          width: 100%;
        }
      }
    }
  }
}
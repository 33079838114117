@import './variables.scss';

.promos-main-container{
  margin-top: -20px;
  .promos-title-container{
    background-color: $secondaryDarkBlue;
    padding: 20px 20px 20px 10vw;
    font-family: $opensans;
    .promos-title{
      color: $white;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 5px;
    }
    .promos-subtitle{
      color: $white;
      font-size: 12px;
    }
  }
  .promos-cards-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 40px;
      .promos-card{
        margin: 20px;
        padding: 30px 10vw 30px 40px;
        background-color: $white;
        text-align: left;
        font-family: $opensans;
        width: 480px;
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .promos-card-title{
          color: $cta;
          font-size: 22px;
          font-weight: 700;
        }
        .promos-card-text1, .promos-card-text2{
          color: $black;
        }
        .promotions-cta-btn{
          color: $white;
          background-color: $cta;
          font-weight: 600;
          font-family: $opensans;
          padding: 12px 26px;
          font-size: 14px !important;
          border: none;
          width: 250px;
          border-radius: 3px;
        }
        .promotions-cta-btn:hover{
          background-color: $ctaLight;
        }
        .promotions-link-cont{
          display: flex;
          flex-direction: row;
          height: 50px;
          .promotions-link{
            height: 40px;
            width: auto;
            margin: 10px 20px 10px 0;
            img{
              height: 40px;
            }
          }
        }
    }
  }
}
    .promos-cta-btn-tabbar{
      color: $white;
      background-color: $cta;
      font-weight: 600;
      font-family: $opensans;
      padding: 12px 26px;
      font-size: 14x !important;
      border: none;
      width: 100vw;
      height: 52px;
      text-transform: uppercase;
    
    }
@media screen and (min-width: 1550px){
  .promos-cards-container{
    padding: 0 10vw;
  }
}
@media screen and (max-width:768px){
  .promos-main-container{
    .promos-cards-container{
      padding-top: 20px;
      .promos-card{
        height: 200px;
        justify-content: space-between;
        margin: 0 0 15px;
        .promotions-cta-btn{
          display: none;
        }
      }
      .promos-card-links{
        height: 300px;
      }
    }
  }
}
@media screen and (max-width:460px){
  .promos-card{
    width: none;
    max-width: 480px;
  }
}
@import './variables.scss';

.double-up-container{
    text-align: left;
    justify-content: center;
    font-family: $opensans;
    line-height: 1.5;
    background-color: $white !important;
    color: $black;
    .double-up-text-container{
        padding: 40px 40px 20px;
        text-align: center;
        font-family: $opensans;
        color: $secondaryGrey;
        .double-up-title{
            font-weight: 300;
            font-size: 48px;
            margin-bottom: 0px;
            color: $secondaryGrey;
        }
        .double-up-text1, .double-up-text2{
            font-weight: 400;
            font-size: 16px;
            padding: 0 28%;
            color: $secondaryGrey;
        }
        .double-up-subtext{
            color: $secondary;
            font-size: 12px;
            margin-bottom: 30px;
        }
        .double-up-qty-image{
            height: 60px;
        }
    }
    .double-up-example-container{
        border-top: 1px solid $black;
        padding: 0 60px 30px;
        display: flex;
        flex-direction: row;
        .double-up-qty-image{
            height: 200px;
            width: auto;
        }
        .double-up-example{
            font-family: $opensans;
            width: 100%;
            padding: 30px;
            .double-up-example-title{
                text-align: center;
                font-weight: 600;
                color: $secondaryGrey;
                font-size: 14px;
            }
            .double-up-example-text-container{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                padding: 20px;
                .double-up-example-column1, .double-up-example-column2{
                    .double-up-example-text{
                        color: $secondary;
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                    .double-up-example-price{
                        font-size: 22px;
                        color: $secondaryGrey;
                        text-align:  center;
                        .double-up-example-crossed{
                            text-decoration: line-through;
                        }
                        .double-up-example-red{
                            color: #E44846;
                            font-weight: 600;
                        }
                    }
                }
            }
            .double-up-example-text-2{
                font-size: 14px;
                color: $secondaryGrey;
                text-align: center;
            }
        }
    }
}

.ant-modal{
    .ant-modal-body{
        padding: 0;
    }
    .ant-modal-footer{
        border-top: 0;  
        text-align: center;
        padding:20px 0 40px;
    }
}

@media screen and (min-width:769px){
    .ant-modal{
        width: 800px !important;
    }
}

@media screen and (max-width:768px){
    .ant-modal{
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 !important;
    }
    .double-up-container {
        .double-up-text-container{
            padding: 40px 40px 20px;
            .double-up-text1, .double-up-text2{
                padding: 0;
            }
        }
        .double-up-example-container {
            padding: 0 10px 20px; 
            .double-up-qty-image{
                height: 160px;
            }
            .double-up-example {
                padding: 30px 0 10px;
                .double-up-example-text-container{
                flex-direction: column;
                text-transform: lowercase;
                padding: 5px;
                .double-up-example-column1{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    height: 16px;
                    .double-up-example-price{
                        font-size: 12px;
                        color: $secondary;
                        margin-bottom: 0;
                    }
                }
                .double-up-example-column2{
                    .double-up-example-text{
                        text-align: center;
                    }
                }
                .double-up-example-text-2{
                    padding-bottom: 0;
                }
            }
        }
    }

    }
    
}

@import './variables.scss';
.partners-main-container{
  padding-top: 40px; //temporary fix 
  padding-bottom: 40px;
  .partners-heading{
    text-align: center;
    font-size: 28px;
    color: $black;
    padding: 30px;
    line-height: 1.2 !important;
  }
  .cards.columns{
    margin-bottom: 20px;
    .card{
        height: 100%;
        background-color: $white;
        
        figure{
            margin-bottom: 0;
        }
        .card-content{
            
            padding: 1rem 1rem 0 1rem;
            .titles{
                font-size: 14px;
                font-weight: 600;
            }
            .content{
                font-size: 14px;
            }
        }
    }
  }
}
@media screen and (max-width: 768px){
  .partners-main-container{
    padding-top: 0; // temporary fix
    padding-bottom: 0;
    .partners-heading{
      font-size: 22px;
      padding: 20px;
    }
  }

}
@import './variables.scss';
 @import './empty-cart-page.scss';
 
.cart__holder{
    padding: 0 30px 30px 30px;
}
.cart-page-container{
    padding: 0;
    display: flex;
    justify-content: center;
    .main__header{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        background-color: $altbackground !important;
        padding-bottom: 0 !important;
        cursor: default;
        .your-cart-title{
            font-family: $playfair;
            font-size: 22px;
            font-weight: 500;
        }
        .cart-icon-cart{
            margin-right: 7px;
            height: 18px;
            width: 18px;
            float: left;
            margin-bottom: 4px;
        }
    }
}


.double-up-info-container{
    margin-bottom: 20px;
    cursor: pointer;
}

.continue-shopping-link{
    font-size: 12px;
    font-family: $opensans;
    color: $black;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
}
.continue-shopping-arrow{
    height: 14px;
    padding-left: 5px;
}
.cart-list-container{
    margin-bottom: 50px;
    max-width: 468px;
}
.cart-btn{
    background-color: $cta;
    color: $white;
    font-family: $opensans;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border:none;
    display: block;
    width: 100%;
    height: 52px;
    margin-bottom: 0;
    border-radius: 4px;
}
.cart-btn:focus, .go-to-cart-btn{
    outline:none;
}
.cart-btn:hover{
    background-color: $ctaLight;
}
.go-to-cart-btn{
    background-color: $secondary;
    text-align: center;
    border:none;
    width: 100%;
    height: 44px;
    .go-to-cart-icon{
        margin-top: 15px;
        height: 14px;
        margin-right: 10px;
    }
    .go-to-cart-text{
        display: inline-block;
        margin: 13px 0;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        font-family: $opensans;
    }
}

.ant-input-number-input {
    border: none;
    text-align: center;
}
.select-option{
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none !important;
    border: none;
    border-radius: 1px;
    font-family: $opensans;
    color: $midgrey;
    height: 30px !important;
    line-height: 2;
    padding-left: 10px;
    background: url('https://storage.googleapis.com/myphoto-img/icons/arrows-up-and-down.png') no-repeat;
    background-position: right center;
    background-color: $altbackground;
  }

  // SALESCHANNEL_56 fifty percent Discount
    .channel56-discount-text{
        text-align: right;
        color: $success;
        font-size: 12px;
        margin-right: 30px;
        font-weight: 600;
        margin-bottom: 0;
    }

//subtotal and shipping:

.cart-action-container{
    display: flex;
    padding: 0.75rem;
    .cart-total-list{
        flex:5;
        display: flex;
        justify-content: space-around;
        font-family: $opensans;
        font-weight: 600;
        align-items: center;
        margin: 7px 15px 0;
        .cart-shipping, .cart-subtotal-text, .cart-subtotal{
            font-size: 13px !important;
        }
        .cart-shipping-type{
            flex:1;
            color: $cta;
        }
    }
}

.modal__content{
    background-color: $altbackground;
}
.ant-modal-close{ //close button
    float: right !important;
    top: 0 !important;
    .ant-modal-close-x{
        width: 65px !important;
        height: 65px !important;
        font-size: 30px;
    }
}

////****** media queries ********

@media screen and (max-width:768px){
    .cart-btn{
        margin: 8px;
        border-radius: 3px !important;
    }
    .tab-bar-ctas, .inline-ctas{
        visibility: hidden;
    }
    .cart-tab-bar{
        background-color: $white !important;
    }
    .cart-page-container{
        padding: 0;
        padding-bottom: 60px;
        .main__header{
            padding-left: 15px;
            margin-top: 0;
            margin-bottom: 15px;
            //justify-content: space-around;
            .your-cart-title{
                font-size: 18px;
            }
        }
    }
    .cart-list-container{
        margin-bottom: 0;
        max-width: 456px;
    }
    .cart-product-details{
        padding-top: 30px;
    }
    .modal__content{
        padding: 0 ;
    }
    
    .continue-shopping-link{
        margin-top: 4px;
    }
    .cart-action-container{
        .cart-total-list{
            .cart-shipping, .cart-subtotal-text{
                font-size: 12px;
            }
        }
    }
}

//Iphone 5:
@media screen and (max-width: 345px){
    .main__header{
        .your-cart-title{
            font-size: 16px;
        }
        .cart-icon-cart{
            margin-right: 5px;
            height: 16px;
            width: 16px;
            float: left;
            margin-bottom: 4px;
        }
    }
}
.modal__header{
    position: sticky;
    z-index: 3;
    top: 0;
    background-color: $altbackground ;
    padding: 20px 0 10px 0;
}

@import './variables.scss';
@import './address-form.scss';
@import './checkout-table.scss';

.pac-container{
  overflow: hidden!important;
}

.checkout__page {
  h3 {
    padding-bottom: 10px;
  }

  .Select-input {
    height: 29px;
  }

  .braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
    height: 40px;
  }

  .braintree-form__field label {
    height: 47px;
  }

  .braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__icon-container {
    margin-top: -13px;
  }
}

hr {
  border-top-color: $black !important;
  border-top-width: 3px !important;
  border-top-style: solid !important;
}

.half_width {
  width: 50%;

  &:nth-child(odd) {
    padding-right: 10px;
  }

  &:nth-child(even) {
    padding-left: 10px;
    clear: both;
  }

}

label {
  margin-bottom: 0 !important;
}

.checkout__list,
.payment__list,
.card__list,
.inverted__list {
 @extend .address__list;
}

.payment__list__item,
.checkout__list__item,
.card__list__item,
.inverted__list__item {
  @extend .address__item;
  width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.checkout__list__item,
.checkout__pay__method,
.checkout__gift__note__holder {
  padding-bottom: 20px;
}

.checkout__pay__method {
  overflow: hidden;
}

.payment__list {
  padding-top: 15px !important;
}

.button__alert {
  padding: 10px 0 0 0;
}
.payment__list__item {
  overflow: hidden;

  &.gift__code {
    padding-right: 90px;
    position: relative;

    .btn {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      box-sizing: border-box;
    }
  }
}

.card__list__item {
  float: none;
  width: 100%;
  clear: both;

  &:nth-child(even) {
    padding-left: 0px;
  }

  &:nth-child(odd) {
    padding-right: 0px;
  }
}

.inverted__list__item {
  &:first-child {
    float: right;
    padding-left: 20px;
    padding-right: 0px;
  }

  &:last-child {
    float: left;
    padding-left: 0px;
    padding-right: 20px;
  }
}

.expiration__date {
  &:after {
    @extend .address__list:after
  }

  .Select {
    float: left;
    width: calc(50% - 5px);

    &:first-child {
      margin-right: 10px;
    }
  }
}

.icon__wrapper {
  display: block;
  position: relative;
  padding-left: 34px;

  &.icon-person:before,
  &.icon-mail-envelope-closed:before,
  &.icon-gift:before {
    font-size: 20px;
  }

  &:before {
    display: block;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 34px;
    text-align: center;
    font-size: 16px;
  }
}

.checkout__payment__methods__table {
  width: 100%;
}

.checkout__payment__methods__td {
  border-style: solid;
  border-color: $black;
  border-width: 1px;
  padding: 10px;
}

@media (max-width: $tablet-width) {

  .payment__list__item,
  .checkout__list__item,
  .card__list__item,
  .inverted__list__item {
    &:nth-child(odd),
    &:nth-child(even) {
      padding-left: 0px;
      padding-right: 0px;
    }

    &.gift__code {
      padding-right: 90px;
    }
  }

  .checkout__list {
    &.simple {
      .checkout__list__item {
        padding-bottom: 0;
      }
    }
  }
}

.containers{
  background-color: $altbackgrounddark;
  padding: 20px 30px;
  margin-bottom: 20px;
}
.order-summary-container{
  background-color: $white;
  padding: 20px 30px 0 30px;
}

.ant-form-item, .ant-input{
font-family: $opensans !important;
}

.input-ant, .ant-input{
  border-radius: 0px !important;
  border: none !important;
}

.Select-value{
  padding-top: 7px;
  line-height: inherit !important;
}
.Select-placeholder{
  line-height: 40px;
}
.Select-control{
  width: 150px;
  //height: 10px !important;
  border-radius: 0px !important;
  border: none !important;
}
.ant-btn{
  color: $white !important;
  background-color: $secondary !important;
  font-family: $opensans;
  font-weight: 600 !important;
  font-size: 12px !important;
  border-radius: 0px !important;
  border: none !important;

}
.gift__note__text__area{
  width: 100%; 
  border: none !important; 
  padding: 5px;
  line-height: 1;
}
.textarea-giftnote{
  resize: none;
  width: 315px;
  border: none;
  outline: none;
}
// .ant-btn-big{
//   font-size: 24px !important;
//   padding: 16px !important;
// }
.checkout__table{
  .is-4{
    text-align: right;
  }
}
.alert__message{
  display: block;
  color: $failure;
  font-weight: 400;
  margin-bottom: 0px !important;
}
.alert__message_zipCode{
  display: block;
  color: $failure;
  font-weight: 400;
  margin-bottom: 0px !important;
  line-height: 1.4;
  width: 100px;
}
.success_alert__message {
  display: block;
  color: $success;
  font-weight: 400;
  margin-bottom: 0px !important; }

.checkout__actions{
  text-align: center;
  width: 100%;
}
.paypal-checkout-btn{
  background-color: #2C2E2F; // paypal color
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  height: 50px;
}
.cc-checkout{
  margin-bottom: 16px;
  background-color: $cta;
  color: $white;
  font-family: $opensans;
  font-size: 17px;
  font-weight: 600;
  border: none;
  font-weight: 600;
  width: 100%;
  height: 52px;
  border-radius: 3px;
  max-width: 750px;
  .lock-img{
    height: 15px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}
.affirm-checkout{
  margin-bottom: 16px;
  background-color: $white;
  color: $black;
  font-family: $opensans;
  font-size: 17px;
  font-weight: 600;
  border: none;
  font-weight: 600;
  width: 100%;
  height: 52px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.65) !important;
  max-width: 750px;
  .affirm-logo{
    height: 19px;
    margin-left: 10px;
    margin-bottom: 13px;
  }
}
.place-order-btn{
  background-color: $cta !important;
  color: $white;
  font-family: $opensans;
  font-size: 17px !important;
  font-weight: 600;
  border: none;
  font-weight: 600 !important;
  width: 100%;
  height: 52px !important;
  border-radius: 3px !important;
  max-width: 750px !important;
}
.in-summary{
  padding: 0 0 30px;
}
.place-order-btn:hover, .place-order-btn:focus{
  color:$white !important;
}
.place-order-btn:hover, .cc-checkout:hover{
  background-color: $ctaLight !important;
}
.button.affirm-checkout:hover{
  border: 1px solid rgba(0,0,0,0.65) !important;
}

.paypal-button.paypal-button-shape-pill{

  border-radius: 3px !important;
}

.paypal-button-text{
  display: none !important;
}

.paypal{
  z-index: 1;
}
.promo-gift-btns{
  color: $linkBlue !important;
}
.promo-gift-btns:hover{
  cursor: pointer;
}
.StripeElement.PaymentRequestButton{
  max-width: 100% !important;
  margin: 16px 0 20px !important;
}
@media screen and (max-width: 768px){

  .lock-icon{
    height: 16px !important;
    padding-left: 12px !important;
    //margin-bottom: 5px;
  }
  .cart__total__holder{
    margin-bottom: 0px;
  }
}

.apt-not-valid{
  padding: 20px 10%;
  text-align: center;
}
.address-not-valid{
  padding: 20px 10%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.address-not-valid-deliveryLine{
  //padding: 20px 10%;
  text-align: center;
}

.checkout-title, .order-summary-title{
  font-family: $playfair;
  font-size: 24px;
  font-weight: 400;
}
.cart__total__holder{
  input{
    border: none;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    padding: 4px 11px;
  }
  input:hover, input:focus{
    outline: none;
  }
}

.lock-icon{
  height: 20px;
  padding-left: 15px;
  margin-bottom: 5px;
}
.gift-icon {
  margin-bottom: 6px;
  padding-right: 6px;
  height: 16px;
}
.checkout-proceed-anyway-button {
  background-color: $cta !important;
  text-transform: uppercase;
  font-family: $opensans;
  letter-spacing: 1.2px;
}
.checkout-go-back-and-edit-button {
  text-transform: uppercase;
  font-family: $opensans;
  letter-spacing: 1.2px;
}
.checkout-tab-bar{
  border-top: 1px solid $altbackgrounddark;
  margin-bottom: 0 !important; /// remove if guarantee ribbon not in tab bar
  padding: 5px;
  background-color: $white;
}
.bottom-nav-bar-container{
  position: fixed;
  z-index:105;
  left: 0;
  right: 0;
  bottom: 0;
  .tab-bar-container{
    //border-top: 1px solid $altbackgrounddark;
    padding: 0 0 10px;
    background: rgb(255, 255, 255); /* fallback color */
    background: rgba(255, 255, 255, 0.9);
  }
  .total-ammount{
    font-size: 13px;
    margin-top: 10px;
    font-family: $opensans;
    font-weight: 600;
  }
}
.shipping-label-price{
  float: right;
  margin-top: -20px;
}
.label-text{
  margin-left: 6px;
}
.label-text-strong{
  font-weight: 600;
  color: #000000; // sorry Mariela -- I had to
}
.place-order-btn[disabled] {
  cursor: not-allowed !important;
  background-color: $ctaLight !important
 }
 .affirm-checkout[disabled] {
  cursor: not-allowed !important;
  background-color: $white !important
 }
.checkout-columns{
  display: flex;
  .checkout-info{
    flex: 8;
    margin: 10px;
  }
  .order-summary-container{
    flex: 4;
    margin: 10px;
    height: 100%;
  }
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 0px !important;
}
.terms-privacy-cont{
  margin: 30px 10% 20px;
  .terms-privacy-text{
    font-size: 12px;
    font-family: $opensans;
    text-align: center;
    .underlined{
      color: $black;
      text-decoration: underline !important;
    }
    .underlined:hover{
      color: $secondary;
    }
  }
}
.upsell-link-container{
  margin-bottom: 20px;;
  .deal-icon{
    height: 10px;
    margin: 0 5px 3px 0;
  }
  .upsell-link{
    text-decoration: underline;
    font-size: 14px;
    font-family: $opensans;
    color: $linkBlue;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .checkout-columns{
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    .checkout-info, .order-summary-container{
      flex: 1;
      width: 100%;
    }
  }
  .cc-checkout, .affirm-checkout{
    font-size: 15px;
  }
  .label-text{
    font-size: 12px;
  }
  .shipping-label-price {
    margin-top: -18px;
  }
  .place-order-btn{
    font-size: 14px !important;
  }
  .payment__list {
    padding-top: 5px !important;
  }
  .terms-privacy-cont{
    margin: 0 10% 20px;
  }
  #cardForm {
    padding: 0 !important;
  }
}

/// BrainTree

.hosted-field {
  height: 32px;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 12px;
  display: inline-block;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #dddddd;
  line-height: 20px;
  background: $white;
  margin-bottom: 12px;
  background: linear-gradient(to right, $white 50%, #fcfcfc 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 300ms ease-in-out;
}

.hosted-fields--label {
  font-family: $opensans;
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
}

.button-container {
  display: block;
  text-align: center;
}

.button {
  cursor: pointer;
  font-weight: 500;
  line-height: inherit;
  position: relative;
  text-decoration: none;
  text-align: center;
  border-style: solid;
  border: none;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
}

.button--small {
  padding: 10px 20px;
  font-size: 0.875rem;
}

.button--green {
  outline: none;
  background-color: $cta;
  border-color: $cta;
  color: $white;
  transition: all 200ms ease;
  height: 45px !important;
  font-size: 18px !important;
  width: 100%;
}

.button--green:hover {
  background-color: $ctaLight;
  color: $white;
}

.braintree-hosted-fields-focused {
  border: 1px solid $btValidGreen;
  border-radius: 1px;
  background-position: left bottom;
}

.braintree-hosted-fields-invalid {
  border: 1px solid $btInvalidRed;
}
.valid{
 color: $btValidGreen !important;
}
.braintree-hosted-fields-valid {
  border: 1px solid $btValidGreen;
}

#cardForm {
  max-width: 50.75em;
  margin: 0 auto;
  padding: 1.875em;
}
@import './variables.scss';

.main-container-404{
    padding-bottom: 0;
    
}
.not-found-container{
    text-align: left;
    padding-top: 20px;
    padding-left: 10%;
    .not-found-title{
        font-size: 80px;
        font-weight: 700;
        color: $cta;
        font-family: $opensans;
        margin-bottom: 10px;
        padding-top: 40px;
    }

    .not-found-text{
        font-size: 24px;
        font-family: $opensans;
        line-height: 1.5 !important;
        margin-bottom: 40px;
    }
    
        .go-home-btn{
            background-color: $cta;
            color: $white;
            font-family: $opensans;
            font-size: 18px;
            padding: 10px 22px;
            text-transform: uppercase;
            border: none;
            border-radius: 2px;
            cursor: pointer;
        }
        .go-home-btn:focus{
            outline:none;
        }
}
.image-page-not{
    width: 100%;
 }
@media screen and (max-width:900px){
    .page-not-found-container.columns{
        flex-direction: column;
        .column{
            width: 100%;
        }
    }
    .not-found-container{
        .not-found-title{
            padding-top: 0;
            
        }
        .not-found-text{
            font-size: 20px;
            
        }
    }
    .image-page-not{
        margin-top: 20px;
     }
}
@media screen and (max-width:768px){

    
    .not-found-container{
        
        .not-found-title{
            padding-top: 0;
        }
    }
    .image-page-not{
        margin-top: 20px;
     }
     .not-found-container{
         padding-top: 0;
     }
}
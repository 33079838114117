@import '../../assets/stylesheets/variables.scss';
@import 'node_modules/react-modal-video/scss/modal-video.scss';
.modal-video{
    background: $modalOverlay;

    @media only screen and (max-width: 767px) {
      .modal-video-body {
        .modal-video-close-btn {
          right: 0;
        }
      }
    }

    @media only screen and (max-height: 414px) and (min-width: 768px) and (max-width: 896px) and (orientation: landscape){

      .modal-video-body {
        width: 80% !important;
        left: 10% !important;

        .modal-video-inner {
          height: 80% !important;
          top: 10% !important;
        }

        .modal-video-close-btn {
          top: 0;
        }
      }
      
      
      
      
    }
}
.hero__section {
  padding: 0  !important;

  .tv-logo-container {
    img {
      cursor: pointer;
      display: inline-block;
      max-width: 100%;
      height: auto;
      max-height: 8%;
      position: absolute;
      right: 1.3%;
      bottom: 5%;

      @media screen and (min-width: 1024px) and (orientation: portrait){
        bottom: 9%;
      }
      @media screen and (min-width: 1024px) and (orientation: landscape){
        bottom: 9%;
      }

      @media only screen and (min-width: 768px) and (max-width: 896px) and (orientation: landscape){
        bottom: 9%;
      }
      // ipad air 4
      @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
        bottom: 9%;
      }
    }    
  }
}

.hero__link {
  display: block;
  padding: 0; 
  text-align: center !important;
  display: flex;
  video{
    width: 50vw;
  }
  .hero-content-container{
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 7vw;
    .hero-content-title{
      font-family: $playfair;
      font-size: 36px;
      font-weight: 400 !important;
      color: $secondaryGrey;
    }
    .hero-content-subtitle{
      font-family: $opensans;
      font-size: 14px;
      font-weight: 400;
      color: $secondaryGrey;
      margin-bottom: 20px;
    }
    .try-it-btn-container{
      .try-it-btn{
        width: 280px;
    }
  }
  }
}
.hero__mobile {
  display: none;
}

@media (max-width: $tablet-width) {
  .hero__desktop {
    display: none;
  }
  .hero__mobile {
    display: block;
  }
}

.tv-logo-container{
  display: block;



  .tv-logo-link{
    .tv-logo-image {
      display: inline-block;
      max-width: 100%;
      height: auto;
      max-height: 15% !important;
      position: absolute;
      right: 5%;
      bottom: 3%;
    }
  }
  
}

@media (min-width: 769px) and (max-width: 1000px){
  .hero-content-title{
    font-size: 26px !important;
  }
}
@media screen and (max-width: 768px){
  .hero__section {
    padding-top: 0 !important;
  }
  .hero__link {
    flex-direction: column;
      video{
        width: 100vw;
      }
    .hero-content-container{
      order: -1;
      padding: 20px 7vw;
      .hero-content-title{
        font-size: 20px;
      }
    }
  }
}

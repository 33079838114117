@import './variables.scss';

.order-status-main-container{
  padding-top: 50px;
  padding-bottom: 120px;
  display: flex;
  justify-content: center;
  .order-status-container{
    max-width: 600px;
    flex: 1;
    color: $black;
    .order-status-header-container{
      margin-bottom: 40px;
      .order-status-header{
        font-family: $playfair;
        font-size: 22px;
      }
      .order-not-found-text{
        font-family: $opensans;
        font-size: 14px;
        padding-bottom: 20px;
      }
    }
    .order-status-orders-container{
      background-color: $white;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 30px 30px 0;
      margin-bottom: 40px;
      .order-status-order{
        padding-bottom: 50px;
        .order-heading{
          display: flex;
          border-bottom: 1px solid $secondaryLight;
          justify-content: space-between;
          .order-date{
            font-size: 18px;
            font-family: $opensans;
          }
          .order-status{
            font-family: $opensans;
            font-size: 16px;
            color: $information;
          }
        }
        .order-content{
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          color: $black;
          .order-status-id{
            font-family: $opensans;
            font-size: 14px;
          }
          .order-status-total{
            font-family: $opensans;
            font-size: 14px;
          }
        }
        .order-status-see-more{
          .order-status-card{
            display: flex; 
            max-width: 468px;
            width: 100%;
            margin-top: 15px;
            background: $white;
            .order-status-image-cont{
              flex: 1;
            img{
              width: 100%;
            }
            }
            .order-status-content-cont{
              flex: 3;
              display: flex;
              justify-content: space-between;
              padding-left: 20px;
              align-items: center;
              .order-status-card-item-name, .order-status-card-item-qty{
                font-size: 12px;
                font-weight: 600;
                color: $black;
                margin-bottom: 0;
                }
              .order-status-card-is-miltiphoto{
                font-size: 10px;
                margin-right: 10px;
                line-height: 1.2 !important;
                color: $secondary;
              }
            }
          }
        }
        .order-status-shipping-cont{
          margin-top: 20px;
          .order-status-shipping-text{
            color: $black;
            font-family: $opensans;
          }
        }
      }
    }
    .order-status-email-container{
      .order-status-email-text{
        font-family: $opensans;
        font-size: 14px;
        padding-bottom: 5px;
      }
      .order-status-email-input{
        max-width: 240px;
      }
      .order-status-email-btn{
        font-size: 12px !important;
        font-family: $opensans;
        font-weight: 400;
      }
      .token-error-message{
        margin-top: 40px;
        color: $failure;
        p{
          font-size: 13px;
          margin-bottom: 5px;;
        }
      }
    }
  }
}

.token-modal-container{
  padding: 60px;
  color: $black;
  .token-modal-header{
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .token-modal-text1{
    font-size: 14px;
    margin-bottom: 20px;
  }
  .token-modal-text2{
    font-size: 12px;
  }
}

@media screen and (max-width:768px){
  .order-status-main-container{
    margin-top: 0;
    padding-top: 20px;
    .order-status-container .order-status-header-container, .order-not-found-container{
      padding: 20px;
      margin-bottom: 0;
    }
  } 
  .token-modal-container{
    padding: 60px 40px;
  }
}
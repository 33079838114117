@import './variables.scss';

.reviews-container{
    text-align: center;
    margin-top: -22px;
    background-color: $white;
    .reviews-title{
        font-size: 30px;
        font-family: $playfair;
        margin: 30px;
        text-align: center;
    }
    .title2{
        margin: 50px auto 30px;
    }
    .reviews-card{
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 20px;
        .right{
            order: -1;
        }
        .reviews-card-image{
            img{
                max-width: 500px;
            }
        }
        .reviews-card-content{
            max-width: 500px;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .reviews-card-stars{
                width: 100px;
                margin: 20px auto;
            }
            .reviews-card-review{
                font-size: 20px;
                font-family: $opensans;
                max-width: 340px;
                color: $secondaryGrey;
                font-weight: 400 !important;
                line-height: 1.5 !important;
            }
            .reviews-card-reviewer{
                font-size: 14px;
                font-family: $opensans;
                color: $secondaryGrey;
                font-style: italic;
            }
        }
    }
    .reviews-images-grid{
        margin: 20px auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 20vw 20vw 40vw;
        grid-template-rows: 20vw 10vw 20vw;
        justify-content: center;
        .inspiration-1{
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
        .inspiration-2{
            grid-column: 1 / 2;
            grid-row: 2 / span 2;
        }
        .inspiration-3{
            grid-column: 2 / 3;
            grid-row: 1 / span 2;
        }
        .inspiration-4{
            grid-column: 2 / 3;
            grid-row: 3 / 4;
        }
        .inspiration-5{
            grid-column: 3 / 4;
            grid-row: 1 / 4;
        }
    }
    .reviews-relive-container{
        background-color: $secondaryGrey;
        padding: 60px 80px 80px;
        margin-bottom: 10px;
        .title3{
            padding: 0 20px 30px;
            color: $white;
        }
        .react-player-component{
            margin: 0 auto;
            width: 80vw !important; 
            height: 45vw !important;
        }
    }
}

// mobile
@media screen and (max-width:768px){
    .reviews-container{
       // padding-top: -10 !important;
        margin-top: -30px; 
        .reviews-title{
            font-size: 27px;
        }
        .title1{
            margin: 20px 50px;
        }x
        .title2{
            margin: 0 30px 40px;
        }
        .reviews-card{
            flex-direction: column;
            margin-bottom: 0;
            .right{
                order: 1;
            }
            .reviews-card-image{
                img{
                    width: 100%;
                }
            }
            .reviews-card-content{
                padding: 30px 20px;
                margin: 0 auto;
            }
        }
        .reviews-images-grid{
            margin: 20px auto;
            grid-gap: 5px;
            grid-template-columns: 30vw 15vw 45vw;
            grid-template-rows: 45vw 30vw 45vw;
            .inspiration-1{
                grid-column: 1 / 3;
                grid-row: 3 /  4;
            }
            .inspiration-2{
                grid-column: 3 / 4;
                grid-row: 3 / 4;
            }
            .inspiration-3{
                grid-column: 1 / 2;
                grid-row: 1 /  2;
            }
            .inspiration-4{
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
            .inspiration-5{
                grid-column: 2 / 4;
                grid-row: 1 / 3;
            }
        }
        .reviews-relive-container{
            padding: 20px;
            .title3{
                padding: 0 20px 10px;
                margin: 20px;
            }
            .react-player-component{
                margin: 0 auto;
                width: 90vw !important; 
                height: 45.6vw !important;
            }
        }
    }
}

@media screen and (min-width: 1088px){
    .reviews-container .reviews-relive-container .react-player-component{
        margin: 0 auto;
        width: 870px !important; 
        height: 490px !important;
    }
}

// Yotpo Reviews Overrides:

.yotpo .yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon,
.yotpo .yotpo-bottomline .yotpo-icon-star, .yotpo .yotpo-bottomline .yotpo-icon-half-star, 
.yotpo .yotpo-bottomline .yotpo-icon-empty-star {
    color: $information !important;
}

.yotpo-product-related-wrapper {
    background-color: transparent !important;
}

.yotpo.yotpo-main-widget .yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2
.yotpo-star-distribution .yotpo-star-distribution-content .yotpo-distributions-graphs
.yotpo-star-distribution-graph .yotpo-star-distribution-score-wrapper
.yotpo-star-distribution-graph-score, .yotpo .yotpo-modal-dialog
.yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2 .yotpo-star-distribution
.yotpo-star-distribution-content .yotpo-distributions-graphs .yotpo-star-distribution-graph
.yotpo-star-distribution-score-wrapper .yotpo-star-distribution-graph-score,
.yotpo .yotpo-rating-bars .yotpo-product-related-field-score-bar.yotpo-rating-bar-full,
.yotpo .yotpo-size-bars .yotpo-product-related-field-score-bar.yotpo-rating-bar-full {
    background-color: $secondary !important;
}
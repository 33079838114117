@import './variables.scss';

.best-main-container{
    padding-bottom: 60px;
    .best-banner-container{
        position: relative;
        .best-text-container{
            position: absolute;
            padding: 10px  40vw 10px 10vw;
            height: 100%;
            display: flex;
            flex-direction: column; 
            justify-content: center;
            .best-banner-title{
                font-family: $playfair;
                font-size: 34px;
            }
            .best-banner-additional-title{
                font-family: $playfair;
                font-size: 34px;
                margin-bottom: 0;
            }
            .best-banner-text-1 {
                font-family: $opensans;
                font-size: 14px;
                max-width: 400px;
                margin-bottom: 20px; 
            }
            .try-it-btn{
                width: 280px;
            }
        }
    
    }
    .best-img-mobile, .best-img-desktop{
        width: 100%;
    }
}
.try-it-btn{
    background-color: $cta;
    color: $white;
    font-family: $opensans;
    font-size: 14px;
    width: 130px;
    height: 40px;
    border: none;
    border-radius: 3px;
    line-height: 1;
    .free-preview-icon{
        height: 20px;
        margin-left: 10px;
        margin-top: -5px;
    }
    .upload-icon{
        padding-top: 1px;
        height: 17px;
        margin-right: 5px;
    }
}
.try-it-btn:focus{
    outline:none;
}
.try-it-btn:hover{
    opacity: 0.8;
}
@media (min-width: 768px ) and (max-width:1088px){
    .best-main-container{
        .best-banner-container{
            .best-text-container{
                padding: 15px  40vw  20px 10vw;
                .best-banner-title{
                    font-size: 30px;
                }
                .best-banner-text-1{
                    display: none;
                }
                
            }
        }
    }
}

@media screen and (max-width:768px){
    .best-main-container{
        padding-top: 0;
        .best-banner-container{
            .text-container-bottom{
                text-align: center;
                justify-content: flex-end;
                width: 100% !important;
            }
            .best-text-container{
                padding: 20px 20px 0;
                width: 40vw;
                .best-banner-title, .best-banner-additional-title{
                    margin-bottom: 5vw;
                    font-size: 22px;
                }
                .best-banner-text-1{
                    display: none;
                }
            }
        }
    }
}
@import '../../assets/stylesheets/variables.scss';

$top-padding: 70px;

.rainbow__bar {
  background: $grey;
  // height: 9px;
  // background-image: linear-gradient(-90deg, #282E75 0%, #00A4C6 41%, #00A04D 57%, #F8E219 68%, #CA2437 91%, #D12680 100%);
}
footer {
  color: $white;
  background: $secondaryGrey;
  font-family: $opensans;
}
.footer-title{
  font-size: 16px;
  text-align: center;
}
footer a {
  color: $white;
  text-decoration: none;
  font-weight: 700;
  font-family: $opensans;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
footer a:hover, footer a:focus {
  color: $midgrey;
  text-decoration: underline;
}
footer .social_icon {
  color: $white;
  padding-right: 5px !important;
  height: 22px;
}
footer .social_icon-youtube{
  height: 29px;
}
footer .social_icon:hover, .social_icon-youtube:hover {
  color: $midgrey;
  opacity: 0.8;
}
footer .content {
  margin-top: 0px;
  padding-top: 10px;
}
h3 {
  font-size: 20px;
  font-weight: bold;
  font-family: $opensans;
}
footer h3 {
  color: $white;
  padding-bottom: 10px;
  padding-top: 20px;;
}
.content h3{
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  color: $white;
}
.content h3:not(:first-child),
footer h3.conn {
  float: left;
  padding-right: 15px;
  margin-top: -20px;
}
footer p {
  line-height: 1.5em;
}
p {
  font-size: 14px;
  line-height: 0px;
  font-family: $opensans;
  margin: 0 0 10px;
}
.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
img.img-responsive.featuredon {
  padding: 8px;
}
.text-center {
  width:100%;
  text-align: center;
}
footer .content .row div, footer .content .row img {
  padding-right: 2%;
}
footer div.bottom_line {
  width: 100%;
  border-top: 1px solid $grey;
}
footer .copy_info {
  padding-bottom: 0px;
  font-size: 12px;
  color: $white;
}
.copyright-footer{
  padding: 18px;
  display: flex;
  
  .copy_info{
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    .style19{
      font-size: 12px;
    }
    a{
      padding: 0;
      padding-right: 10px;
    }
  }
  p{
    padding-right: 10px;
  }
}
footer .pp {
  font-size: 12px;
  padding-left: 25px;
  color: $white;
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: 600;
}
.footer__list {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding-right: 80px;
  position: relative;
}
.footer__item {
  float: left;
  box-sizing: border-box;
  padding: $top-padding 0 60px 0;

  &:first-child {
    width: 50%;
    min-height: 350px;
  }

  &:nth-child(2) {
    padding-left: 80px;
  }

  &:last-child {
    position: absolute;
    top: 0;
    right: 0;
    padding: $top-padding 0 0 0;
  }

  h2 {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 24px;
  }
}

.footer__more__list {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.footer__more__item {
  padding: 0 0 20px 0;
}

.footer__social__item {
  padding-bottom: 15px;

  a {
    display: block;
    line-height: 45px;
    width: 45px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      transform: rotate(30deg);
    }
  }
}
.footer-container{
  padding: 20px;
}
.as-featuredon-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  .buzz-logo{
    max-width: 130px;
    min-width: 100px;
    img{
      max-width: 100px;
    }
  }

  @media screen and (min-width: 1024px) and (orientation: portrait){
    padding: 0 80px;
  }
}

.footer-columns{
  display: flex;
  .column{
    padding-right: 20px;
  }
}
@media (max-width: 768px) {
  .footer-columns{
    flex-direction: column;
    .is-2, .is-4{
      width: 100%;
      padding-bottom: 20px;
    }
  }
  .as-featuredon-container{
    justify-content: center;
  }
  .footer-cont-noimages{
    padding-bottom: 52px;
  }
  .footer-cont-hasimages{
    padding-bottom: 74px;
  }
  .footer-container{
    padding-left: 30px !important;
    padding-bottom: 0;
    h3{
     padding:0;
    }
    .conn{
      padding-top: 18px;
    }
  }

  .footer__item {
    padding-left: 0;

    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
      padding-left: 0;
    }

    &:nth-child(2) {
      padding-top: 0;
    }
  }
  .footer-contact-container{
    display: flex;
    
  }
  footer a{
    padding-right: 15px;
  }
  footer .social_icon {
    margin-right: -10px !important;
  }
  .copyright-footer{
    .copy_info{
      .style19{
        font-size: 11px;
      }
    }
  }
}
.footer-connect{
  
  .conn-links-container{
    margin-top: 15px;
  }
}

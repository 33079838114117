@import '../../../assets/stylesheets/variables.scss';

.phone-source {
  padding: 40px 0 10px
}

.photo-phone-done {
  padding: 0 0 30px;
}

.photo-phone-message {
  font-size: 20px;
  text-align: center;
}

.photo-phone-key {
  font-size: 30px;
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border: 1px solid $black;
  min-width: 200px;
}

.deviceUploader {
  padding: 0 30px 40px;

  a.photo-upload-button.btn {
    display: block;
    padding: 0;
    width: 150px;
    height: 150px;
    margin: 0 auto 40px;
    font-size: 80px;
    text-align: center;
    line-height: 141px;
  }
}

.phone__container {
  //max-width: 500px;
  margin: 0 auto;
  padding: 10px;
}

.phone__connection__qr {
  display: block;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  font-size: 23px;
}
.phone__instructions{
  font-size: 18px !important;
  .steps-title{
    font-size: 24px !important;
    font-weight: 400 !important;
    margin-bottom: 20px;
  }
  .phone__connection__title{
    font-weight: 300 !important;
    line-height: 1.5 !important;
    font-size: 18px !important;
  }
}
.send-text-btn .ant-btn {
  background-color: $cta !important;
  font-size: 15px !important;
}

.phone-import-input{
  margin: 15px 0 0 !important;
}
.phone__connection__url,
.code-input {
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid gray;
  padding: 7px;
  font-size: 23px;
  display: block;
  margin: 20px auto 10px;
}

.code-input {
  max-width: 300px;
}

.phone-title {
  margin: 14px 0 0;
}

.phone-photos-btn,
.phone-photos-btn.btn {
  display: block;
  margin: 20px auto 10px;
  width: 200px;
}

.photo-uploader-link-holder {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  padding: 30px 0 0;

  a {
    display: block;

    &:before {
      display: inline-block;
      padding-right: 10px;
    }
  }
}

.images__selection__container {
  min-height: 250px;
}

@media (min-width: 500px) {
  .phone-title {
    margin: 20px 0;
  }

  .phone__connection__title{
    font-size: 25px;
    line-height: 30px;
  }

  .phone__connection__qr {
    font-size: 30px;
  }
}


@import './variables.scss';

.faq-main-container{
    background-color: $white;
    .faq-container{
        padding-top: 40px;
        .faq-title{
            font-family: $playfair;
            font-size: 24px;
        }
        .faq-subtitle{
            font-family: $opensans;
            font-size: 14px;
            color: $black;
            padding-bottom: 30px;
        }
        .faq-category-row{
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 60px;
            .faq-btn{
                border: 1px solid #b3b3b3;
                margin: 10px 5px;
                width: 100%;
                padding: 30px;
                text-align: center;
                max-width: 300px;
                height: 155px;
                display: flex;
                flex-direction: column;
                .faq-btn-img{
                    height: 55px;
                    width: auto;
                    transition: all 0.2s ease-in-out 0s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin: auto 0;
                    }
                }
                .faq-btn-text{
                    margin-top: 15px;
                    color: $black;
                    font-size: 14px;
                    font-family: $opensans;
                }
            }
            .faq-btn:hover{
                .faq-btn-img{
                    -webkit-transform: rotate(8deg) scale(1.1);
                    transform: rotate(8deg) scale(1.1);
                }
            }
        }
      
    }
    
    .faq-customer-exp-container{ /// CustomerExperience.js container
        margin-top: 60px;
        padding: 60px 40px;
        text-align: center;
        background-color: $altbackground;
        .faq-ustomer-exp-title{
            font-size: 24px;
            font-family: $playfair;
            font-weight: 400;
        }
        .faq-ustomer-exp-subtitle{
            font-size: 14px;
            font-family: $opensans;
            color: $black;
        }
        .faq-customer-options{
            margin-top: 60px;
            display: flex;
            justify-content: center;
            text-align: center;
            .faq-customer-option{
                width: 200px;
                .faq-option-text{
                    margin-top: 10px;
                    font-size: 14px;
                    font-family: $opensans;
                    color: $black;
                }
            }
        }   
    }
    .faq-money-back-container{  /// MoneyBackGuarantee.js container
        padding: 30px 15% 60px;
        text-align: center;
        h3{
            margin: 20px 0;
            font-family: $playfair;
            font-size: 24px;
            font-weight: 400;
        }
        p{
            font-size: 16px;
            color: $black;
            font-family: $opensans;
            line-height: 30px !important;
        }
    } 
}
.faq-category-main-container{
    padding-top: 20px;
    padding-bottom: 100px;
    .faq-category-title{
        margin-top: 10px;
        font-family: $playfair;
        font-size: 24px;
    }
    .to-faq{
        margin: 10px 0 30px;
    }
    .faq-questions-container{
        margin-top: 40px;
        width: 80vw;
        // changing ANTD collapse styling only for faq categories menu:
        .ant-collapse{
            border: none;
            // width: 100%;
            background-color: $white;
        }
        .ant-collapse > .ant-collapse-item {
            border-bottom: none !important;
            &:last-child{
                border-bottom: 1px solid #b3b3b3 !important;
            }

        }
        
        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            left: 6px;
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header{
            padding: 0;
            padding-left: 30px;
            font-family: $opensans;
            color: $black;
            background-color: white;
            border-radius: 0;
            font-weight: 600;
        }
        
        .ant-collapse-content > .ant-collapse-content-box{
            padding: 10px 0 15px;
            display: flex;
            flex-direction: column;
            p{
            font-family: $opensans;
            color: $black;
            padding: 10px 5px;
            }

        }
        .ant-collapse-content-active{
            border: 1px solid white;
        }
        .ant-collapse-header{
            border-top: 1px solid #b3b3b3 !important;
            padding-bottom: 10px;
            border: none; 
            width: 100%;
            padding: 20px 10px 20px 30px !important;
            //margin-bottom: -1px;
            .ant-collapse-arrow{
                height: 30px !important;
                left: 10px !important;
            }
        }
        .ant-collapse > .ant-collapse-item > .ant-collapse-header{
            height: auto !important; 
        }
    }
    .faq-link{
        color: $instruction;
    }
    .faq-see-all-link{
        color: $secondary;
    }
}

@media screen and (max-width: 1087px){
    .faq-category-main-container{
        padding: 20px 60px;
        .faq-questions-container{
            width: 100%;
        }
    }
    .faq-main-container .faq-container {
        padding: 20px 40px;
    }
}
@media screen and (max-width: 768px){
        .faq-main-container{
            padding-top: 0;
            .faq-container{
                padding: 20px;
                .faq-category-row{
                    .faq-btn{
                        max-width: 100%;
                    }
                }
            }
            .faq-questions-container{
                width: 100%;
            }
        }
        .faq-category-main-container{
            padding: 20px 20px 100px;
        }
    }


@import './variables.scss';

::selection {
  background: $secondaryLight !important; /* WebKit/Blink Browsers */
  color: $black !important;
}
::-moz-selection {
  background: $midgrey !important; /* Gecko Browsers */
  color: $black !important;
}
.banners-columns{
  display: flex;
  padding: 30px 30px 0;
  justify-content: center;
}
.homepage-title{
  padding: 20px 20px 0;
  font-size: 26px;
  font-family: $playfair;
  text-align: center;
  margin-bottom: 0;
}
.home-banner-container{
  position: relative;
  overflow: hidden;
  margin: 10px;
  .big-banner{
    padding: 30px 5%;
  }
  .small-banner{
    padding: 10px 10%;
  }
  .home-text-container{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100% !important;
    display: flex;
    .home-text{
      width: 100%;
      .button-alignment-left{
        max-width: 350px;
        text-align: center;
      }
      .button-alignment-right{
        max-width: 350px;
        width: 350px;
        text-align: center;
        float: right;

      }
      .home-banner-title{
        font-size: 26px;
        margin-bottom: 0;
        white-space: pre-wrap;
      }
      .home-banner-additional-title{
        font-size: 40px;
        margin-bottom: 0;
        white-space: pre-wrap;
      }
      .font-open-sans{
        font-family: $opensans;
      }
      .font-play-fair{
        font-family: $playfair;
      }
      .home-banner-text-1{
        margin-top: 15px;
        font-size: 14px;
        font-family: $opensans;
        color: $black;
      }
      .banner-cta-btn{
        margin: 10px;
        width: 110px;
        height: 34px;
      }
      .wide-banner-cta-btn{
        margin: 10px;
        width: 90%;
        height: 34px;
      }
    }
  }
  img{
    max-width: 100%;
    width: 100%;
    z-index: 0;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
  }
  &:hover{
    .home-text-container{
      .home-text{
        .home-banner-text-1{
          text-decoration: underline;
          // color: $secondaryDark;
        }
        .banner-cta-btn{
          background-color: $ctaLight;
        }
      }
    }
    img{
        -moz-transform: scale(1.04);
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
    }
  }
}
// Category Button:
.featured-title{
  color: $black;
  font-family: $playfair;
  font-size: 26px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
}
.featured-columns{
  display: flex;
  justify-content: center;
  padding:30px 10px;
  .featured-btn{
    padding: 20px;
    text-align: center;
    width: 300px;
    border: 1px solid $secondaryLight;
    margin: 5px;
    font-size: 20px;
    font-weight: 400;
    font-family: $opensans;
    cursor: pointer;
    a{
      color: $black !important;
    }
  }
  .featured-btn:hover{
    border: 1px solid $secondary;
  }
}

@media screen and (max-width: 1120px){
  .home-banner-container .home-text-container{
    padding: 24px 24px 16px 24px;
    .home-text{
      width: 100%;
      .home-banner-title{
        font-size: 24px;
      }
    }
  }
}

@media(min-width:769px ) and (max-width:1032px) {
  .banners-columns-wrap{
    flex-wrap: wrap !important;
    justify-content: center;
    .home-banner-container{
      max-width: 216px;
    }
  }

}
@media screen and (max-width:768px){
  .homepage-title{
    font-size: 22px;
    margin-bottom: 10px;
    display: none;
  }
  .banners-columns{
    padding: 0;
  }
  .padding-mobile{
    margin: 10px 20px;
  }
  .home-banner-container {
    margin: 0;
    .home-text-container{
      padding: 30px 11px 10px;
      .home-text{
        width: 100%;
        .button-alignment-left, .button-alignment-right{
          max-width: unset;
          text-align: center;
          float: unset;
          width: unset;
        }
        .home-banner-title, .home-banner-additional-title{
          // font-size: 22px !important;
          display: inline;
        }
        .home-banner-additional-title{
          font-size: 26px;
        }
        .home-banner-text-1{
          font-size: 14px;
        }
        .hide-link-desktop{
          display: none;
        }
        .wide-banner-cta-btn{
          margin: 10px;
          width: 60%;
          height: 34px;
          display: inline-block;
        }
      }
    }
  }
  .is-full-width-mobile{
    width: 100%;
    display: inline-block;
    flex-wrap: wrap !important;
    .home-banner-flex{
      flex: 1 !important;
      padding: 0 0 10px 0 !important;
    }
  }
// Category Button:
.featured-title{
  margin-top: 10px;
}
  .featured-columns{
    padding:10px 10px 20px;
    .featured-btn{
      padding: 10px;
      font-size: 16px;
    }
  }
}
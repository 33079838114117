@import './variables.scss';

// AboutLinks.js: 
.about-links-container{
  .about-us-title{
    padding: 35px;
    background-color: $altbackgroundmiddle;
    color: $black;
    font-family: $playfair;
    font-size: 28px;
    text-align: center;
    margin-bottom: 0;
  } 
  .about-us-links{
    padding: 20px;
    background-color: $white;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $altbackgrounddark;
    .about-us-link{
      cursor: pointer;
      color: $black;
      margin: 0 35px;
    }
    .active{
      // text-decoration: underline !important;
      border-bottom: 1px solid $secondaryDark;
    }
  }
}
// end AboutLinks.js ^^

.about-us-container{
  margin-top: -22px;
  .about-us-content{
    padding: 60px 20%;
    background-color: $white;
    text-align: center;
    .about-us-paragraph{
      font-family: $playfair;
      font-size: 22px;
      line-height: 1.8 !important;
      color: $black;
    }
    .about-us-card{
      padding-top: 40px;
      h3{
        font-family: $playfair;
        font-size: 24px;
        font-weight: 400;
        color: $black;
        padding: 20px 0;
      }
      p{
        font-family: $opensans;
        font-size: 16px;
        line-height: 2 !important;
        color: $black;
      }
    }
  }
  .buy-more-card, .gift-ready-card{
    background-color: $altbackgroundmiddle;
    border-bottom: 10px solid $white;
    width: 100%;
    display: flex;
    .image-container{
      flex: 1;
      background-size: contain !important;
    }
    .about-text-container{
      flex: 1;
      padding: 80px 5%;
      h3{
        font-family: $playfair;
        font-size: 24px;
        font-weight: 400;
        color: $black;
        padding: 20px 0;
      }
      p{
        font-family: $opensans;
        font-size: 16px;
        line-height: 2 !important;
        color: $black;
      }
    }
  } 
}

@media screen and (max-width:769px){
  .about-us-container{
    
    .about-us-content{
      padding: 30px 40px;
    }
    .buy-more-card, .gift-ready-card{
      .image-container{
        flex: none !important;
        height: 100vw;
      }
      .about-text-container{
        padding: 30px 40px;
        background-color: $white;
      }
    }
    .buy-more-card{
      flex-direction: column-reverse;
    }
    .gift-ready-card{
      flex-direction: column;
    }
  }
  .about-links-container{
    .about-us-links{
      padding: 20px 10px;
      .about-us-link{
        margin: 0 20px;
      }
    }
  }
}

//iphone 5
@media (max-width: 350px) {
  .about-links-container{
    .about-us-links{
      padding: 20px 10px;
      .about-us-link{
        margin: 0 10px;
      }
    }
  }
}
@import './variables.scss';

.bf-main-container{
    text-align: center;
    .bf-contaier-2{
        padding: 30px;
        position: relative;
        background-color: $altbackgrounddark;
        .galore-heading{
            font-family: $opensans;
            font-size: 24px;
            font-weight: 400;
            padding-bottom: 20px;
        }
        .bf-text{
            font-family: $opensans;
            font-size: 14px;
            padding: 0 20%;
        }
        .bf-ornament{
            position: absolute;
            top: 0;
            right: 50px;
            width: 180px;
        }
    }
    .bf-contaier-3{
        padding: 30px 0;
        .christmas-img{
            background: url("https://storage.googleapis.com/myphoto-img/blackfriday-banner.jpg", "/images/blackfriday-banner.jpg");
            background-position: center;
            background-repeat: no-repeat;
            height: 600px;
        }
    }
    .all-products-btn-container{
        padding-top: 50px;
        padding-bottom: 30px;
        .all-products-btn{
            color: white;
            background-color: $cta;
            font-size: 20px;
            font-family: $opensans;
            font-weight: 400;
            padding: 12px 42px;
            border-radius: 2px;
        }
    }
}

@media screen and (max-width:768px){
    .bf-main-container{

        .bf-contaier-2{
            padding: 20px;
            .bf-text{
                padding: 0 5%;
            }
        } 
        .bf-contaier-3{
            padding: 0;
            .christmas-img{
                background-size: auto;
            }
        } 

    }  
}
@import './variables.scss';

.linked {
    cursor: pointer !important;
    pointer-events: auto;
    .promo-subtext {
        text-decoration: underline;
    }
}
.promo-ribbon-container {
    text-align: left;
    font-size: 14px;
    font-family: $opensans;
    padding: 22px;
    line-height: 1;
    text-align: center;
    height: 60px;
    .promo-text1 {
        color: $white;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight:700;
    }
    .promo-text2 {
        padding:0 5px;
        color: $cta;
        font-weight: 600;
    }
    .promo-text3{
        color: $cta;
    }
    .promo-subtext {
        padding:0 5px;
        color: $white;
        font-size: 12px;
        margin-bottom: 0;
        font-family: $opensans;
    }
    .help-icon{
        height: 12px;
        margin-bottom: 2px;
    }
}

/// Pulsating dot:
.ring-container {
    position: relative;
    margin-right: 20px;
    margin-left: 30px;
    .circle {
        width: 12px;
        height: 12px;
        background-color: $information;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
    }
    // .ringring {
    //     border: 2px solid $information;
    //     -webkit-border-radius: 50%;
    //     height: 16px;
    //     width: 16px;
    //     position: absolute;
    //     left: -2px;
    //     top: 3px;
    //     -webkit-animation: pulsate 1s ease-out;
    //     -webkit-animation-iteration-count: infinite; 
    //     opacity: 0.0
    // }
    // @-webkit-keyframes pulsate {
    //     0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    //     50% {opacity: 1.0;}
    //     100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
    // }
}

@media screen and (max-width:768px){
    .ring-container {
        margin-left: 10px;
    }
    .promo-ribbon-container{
        text-align: center;
        padding: 12px;
        height: 40px;
    }
    .promotion-div-helper{
        display: none;
    }
}
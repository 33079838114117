@import './variables.scss';

.quality-modal-container{
  font-family: $opensans;
  text-align: center;
  color: $black;
  .quality-main-container{
    padding: 40px;
    .quality-title{
      text-transform: uppercase;
      font-size: 26px;
      letter-spacing: 10px;
    }
    .quality-subtitle{
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 6px;
    }
    .quality-text{
      padding: 30px 10% 20px;
      font-size: 14px;
      line-height: 1.5 !important;
    }
    .quality-line{
      margin: 0 15% 30px;
      border-bottom: 1px solid $grey;
    }
    .quality-second-title{
      font-size: 14px;
      margin-bottom: 30px;
    }
    .quality-guarantee-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .quality-column{
        width: 150px;
        .quality-column-img{
          height: 35px;
          margin-bottom: 15px;
        }
        .quality-column-title{
          font-size: 14px;
          font-weight: 600;
        }
        .quality-column-text{
          font-family: 12px;
        }
      }
    }
  }
  .quality-footer-container{
    padding: 30px;
    color: $white;
    background-color: $information;
    width: 100%;
    .quality-footer-text1{
      padding: 0 15%;
      font-size: 18px;
      line-height: 1.2 !important;
      margin-bottom: 0;
    }
    .quality-footer-text2{
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2 !important;
    }
  }
}

@media screen and (max-width:768px){
  .quality-modal-container{
    .quality-main-container{
      padding: 40px 10px;
      .quality-title{
        font-size: 20px;
        letter-spacing: 6px;
      }
      .quality-subtitle{
        font-size: 16px;
        letter-spacing: 4px;
      }
      .quality-text{
        padding: 20px 5% 20px;
        font-size: 14px;
      }
    }
    .quality-footer-container{
      .quality-footer-text1{
        padding: 0;
      }
    }
  }
}
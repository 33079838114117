@import '../../assets/stylesheets/variables.scss';

.slider {
  position: relative;
  overflow-x: auto;
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(90px);
  overflow-y: visible;
  display: flex;
  height: 72px;

  &.mobile,
  &:hover {
    .slider__next {
      right: -8px;
    }

    .slider__prev {
      left: -8px;
    }
  }

  &.display__mode {
    .slider__prev,
    .slider__next {
      top: 70px;
    }
  }
}

.slider__list {
  position: absolute;
  top: 8px;
  left: 0;
  list-style: none;
  width: 100% !important;
  padding-left: 0;
}
.add-btn-text{
  left: -2px;
  width: 60px;
  font-size: 10px;
  margin-bottom: 0;
  position: absolute;
  font-weight: 600;
  bottom: -5px;
}
.slider__item {
  float: left;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 45px;
  width: 45px;
  &.preview,
  &.list {
    cursor: pointer;

    &.onlyShow {
      cursor: auto;
    }

    &:last-child {
      border-right: none;
    }

    .slider__background,
    .slider__display__data__holder {
      width: calc(100% - 10px);

      h3 {
        font-size: 20px;
        padding-top: 10px;
      }

      p {
        padding: 10px 0 0;
        font-size: 14px;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      .tag {
        padding: 5px 0 0;
        font-size: 14px;
      }

      .price__holder {
        font-size: 10px;
        text-transform: uppercase;
      }

      .price {
        font-size: 18px;
        display: block;
        font-family: $opensans;
      }
    }

    &:after,
    &:before {
      content: '';
      display: block;
      width: calc(100% - 10px);
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      transition: all 300ms;
      box-sizing: border-box;
    }

    &.display {
      &:after,
      &:before {
        display: none;
      }
    }

    &:before {
      z-index: 3;
      top: 50%;
      left: calc(50% - 5px);
      width: 20px;
      height: 20px;
      font-family: $icons;
      opacity: 0;
      color: $white;
      text-align: center;
      line-height: 20px;
      font-size: 20px;
      transform: translate(-50%, -50%);
    }

    &.isLowResolution:before {
      content: '\e90e';
    }

    &.upload__slide {
      cursor: auto;
      &:after,
      &:before {
        display: none;
      }
      .slider__buttons__list{
        outline-color: none !important;
        text-decoration: none !important;
        outline: none!important;
      }
      .slider-upload-btn{
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
        width: 45px;
        height: 45px;
        padding: 0;
        // background-color: $cta;
        .upload-icon-slider{
          height: 45px;
        }
        .upload__btn-text:focus{
          outline-color: none !important;
          text-decoration: none !important;
          outline: none!important;
        }

        // @media (max-width: 768px){
        //   padding: 5px 10px;
        // }
      }
      .slider-upload-btn:hover{
        background-color: $ctaLight;
      }
    }
  }
}

.slider__big__icons {
  .slider__item:before,
  .upload__slide .upload__btn:before {
    font-size: 50px;
  }
}

.slider__background,
.slider__display__data__holder {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
  // overflow: hidden;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider__image {
  width: 100%;
}

.slider__hidden__image {
  width: 100%;
  height: 100%;
  min-width: 80px;
  min-height: 45px;
  margin: auto;
  object-fit: cover;
  object-position: 50% 33%;
}

.slider__display__image {
  width: 100%;
  max-width: 100%;
}

.slider__prev,
.slider__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  width: 35px;
  height: 35px;
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
  transition: all 300ms;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 35px;
    font-size: 40px;
    opacity: 1;
    transition: all 0.3s;
    background: rgba($white, 0.4);
  }

  &:hover{
    &:before {
      opacity: 1;
      background: rgba($white, 0.8);
    }
  }
}

.slider__prev {
  left: -40px;
  text-align: right;

  &:before {
    border-radius: 0 50% 50% 0;
  }
}

.slider__next {
  right: -40px;
  text-align: left;

  &:before {
    border-radius: 50% 0 0 50%;
  }
}

.slider__nav__list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 4;
}

.slider__nav__item {
  display: inline-block;
  padding: 10px;
}

.slider__nav__button {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba($white, 0.5);
  text-indent: -9999px;
  padding: 0;
  margin: 0;
}

.preview__list {
  padding: 50px 0 0px;
  font-family: $opensans;
  font-size: 20px;

  h2 {
    padding: 0 0 10px;
    
  }

  &:last-child {
    padding: 30px 0 50px;
  }
}

// PREVIEW
.slider__preview {
  height: 500px;
  position: relative;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 5px;
  // background-color: $ebony-clay;
}

.slider__buttons__list {
  position: absolute;
  top: 8px;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 120px;
  list-style: none;
  padding-left: 0;
}

.delete_button{
    z-index: 10;
    padding: 0 4px 2px 4px;
    background: $secondaryGrey;
    color: $white;
    font-size: 11px;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -18px;
    border: 10px solid transparent;
    background-clip: padding-box;
    line-height: 16px;
    .delete-x-image{
      width: 10px;
      height: 10px;
      filter:brightness(1.5)
    }
}

.in_use {
    z-index: 10;
    padding: 0 4px 2px 4px;
    background: $instruction;
    color: $white;
    font-size: 10px;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    bottom: 2px;
    background-clip: padding-box;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
    box-shadow: 0px 0px 0px 1px rgba(255,255,255,1);
    .in_use_icon {
      width: 10px;
      height: 12px;
      filter:brightness(100)
    }
}

.ant-spin-nested-loading {
  // height: 52px;
  overflow: hidden;
}
.more-views-image{
  width: 100vw;
  height: auto;
}
.slider_selected{
  &:after {
    content:' ';
    position: absolute;
    top: 50px;
    left: 40px;
    width: 80px;
    border: 3px solid $information;
    border-radius: 2px;
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    transform:translateX(-50%);
  }
}
/// product page carousel arrows:
.slick-prev{
  left: 0;
  z-index: 2;
  padding: 7px 9px 7px 6px;
  margin-left: 10px;
}
.slick-next{
  right: 0;
  padding: 7px 6px 7px 9px;
  margin-right: 10px;
}
.slick-prev, .slick-next{
  background: $secondary;
  opacity: 0.7;
  height: 40px;
  width: 40px;
  top: 47%;
  border-radius: 50%;
}
.slick-prev:hover, .slick-next:hover{
  background: $secondary;
  opacity: 0.5;
}

@media (max-width: 1087px){
  .preview__list{
    h2{
      padding-left: 20px;
    }
  }
  
}
@media (min-width: 769px){
  .slider__holder, .photos__list__title {
    margin-left: 20px;
    height: 64px;
  }
}
// hiding on mobile carousel arrows(product page):
@media (max-width: 768px){
  .slider__buttons__list {
    margin-left: 6px;
  }
  .add-btn-text {
    // left: 5px;
    left: 2px;
    bottom: 14px;
  }
  .slider__holder, .photos__list__title {
    margin-left: 8px;
    height: 84px;
  }
}

@import './variables.scss';

.try-it-now-container{
  padding: 30px;
  .try-it-now-text-container{
    text-align: center;
    margin-bottom: 40px;
    h2{
      font-family: $playfair;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 5px;
      text-transform: capitalize;
    }
    p{
      font-family: $opensans;
      font-size: 14px;
      text-transform: uppercase;
      color: $black;
      letter-spacing: 2px;
    }
  }
  .try-it-now-button-container{
    display: flex;
    justify-content: center;
    padding: 20px;
    .try-it-now-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 200px;
      .try-it-now-number{
        height: 40px;
        width: 40px;
        border: 1px solid $black;
        border-radius: 50%;
        vertical-align: middle;
        padding: 10px;
        color: $black;
        font-weight: 300;
        margin-bottom: 30px;
        p{
          font-size: 20px;
          font-family: $opensans;
          text-align: center;
          padding: auto;
          
        }
        
      }
      .try-it-now-text{
        color: $black;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 3px;
        font-family: $opensans;
        text-align: center;
        .try-it-now-break{
          display: block;
          margin-top: 3px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .try-it-now-container{

    .try-it-now-text-container{
      margin-bottom: 0;
      h2{
        font-size: 22px;
      }
    }
    .try-it-now-button-container{
      flex-direction: column;
      align-items: center;
      .try-it-now-section{
        margin-bottom: 20px;;
        .try-it-now-number{
          height: 25px;
          width: 25px;
          vertical-align: middle;
          padding:3px;
          margin: 15px;
          p{
            font-size: 14px;
            font-family: $opensans;
            text-align: center;
            padding: auto;
            
          }
          
        }
        .try-it-now-text{
          font-size: 16px;
          margin-bottom: 0;
          .try-it-now-break{
            display: inline;
          }
        }
      }
    }
  }
}
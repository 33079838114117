@import './variables.scss';

$holidayred: #e44846;
$holidaygreen: #15755c;

.gifting-banner{
    width: 100%;
}
.gifting-banner-container{
    position: relative;
    display: inline-block;
    width: 100%;
    .gifting-banner{
        display: block;
    }
    .gifting-banner-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
        text-align: center;
        width: 70%;
        .gifting-banner-title{
            font-size: 34px;
            color:$white;
            font-family: $opensans;
            font-weight: 700;  
        }
        .gifting-banner-subheader,.gifting-banner-subheader2, .quote-name{
            color:$white;
            font-family: $opensans;
        }
        .gifting-banner-subheader{
            font-size:18px;
            line-height: 1.5 !important;
        }
        .gifting-banner-subheader2{
            font-size:16px;
				}
				.quote-name{
					font-size:16px;
					padding-top: 10px;
					padding-bottom: 15px;
					font-style: italic;
				}
    }
}
.main-container-gifting-form{
    padding-bottom: 0;
}
.gifting-heading{
    font-size:32px;
    font-weight: 700;
    color: $holidaygreen;
}
.gifting-text{
    font-size: 18px;
		padding-bottom: 15px;
		line-height: 1.5 !important;
}
.gifting-text-container{           
    text-align: center;
    font-family: $opensans;
		padding:50px 80px;
}

.gifting-nextstep-container, .gifting-more-container{
    background-color: $altbackgrounddark;
}
.align-left{
    text-align: left;
}

.get-started-btn{
    background-color: $holidayred;
    color: $white;
    font-family: $opensans;
    font-family: $opensans;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border:none;
    display: block;
    width: 100%;
    height: 42px;
    .arrow-icon{
        height: 14px;
        margin-left: 10px;
        margin-top: -2px;
    }
}
.get-started-btn:hover, .get-started-btn:focus{
	outline: none;
}
.submit-btn{
    color: $white;
    background-color: $holidayred;
    border: none;
    border-radius: 5px;
    font-family: $opensans;
    font-size: 24px;
    padding: 3px 24px;
    float:right;
    margin-right: 17%;
    .arrow-icon{
        height: 26px;
        margin-left: 10px;
        margin-top: -5px;
      }
}
.gifting-form-heading{
    font-size: 20px;
   line-height: 22px;
   font-family: $opensans;
	 font-weight: 700;	 
}
.form-download{
    color:$black;
	text-decoration: underline;
}
.form-download:hover{
    color: $midgrey;
    text-decoration: underline;
}
.gifting-form-heading{
    text-align: left;
    .corporate-gifting-heading{
        margin-top: 30px;
        font-weight: 800;
        font-size: 28px ;
        line-height: 1.5 ;
        font-family: $opensans;
    }
    .corporate-form-heading{
        font-size: 16px ;
        line-height: 1.5;
        font-family: $opensans;
        color: $black;
        font-weight: 500;
        padding-bottom: 30px;
    }
}
.gifting-text-small{
    font-size: 16px;
}.circle-container{
    margin: 22px 0 14px 0;
    .circled-number{
        margin:20px 0 10px 0;
        font-size: 16px;
        color: $white;
        background-color: $holidayred;
        padding:6px 13px;
        height: 25px;
        width: 25px;
        border-radius: 50%;
    }
}
.gifting-more-container{
    position: relative;
    .gifting-ribbon{
        position: absolute;
        top: 0;
        right: 0;
        width: 35vw;
    }

}
.table{
    font-family: $opensans;
    box-shadow: 5px 4px 22px 0 rgba(0, 0, 0, 0.1), 5px 6px 30px 0 rgba(0, 0, 0, 0.1);
    .table-head{
        font-weight: 400;
        font-size: 14px;
        background-color:$holidaygreen;
        border: none;
        th{
            border: none;
            text-align: center;
            color:$white;
        }
    }
    .table-body{
        font-size: 14px;
        td{
            text-align: center;
            border: none;
        }
    }
}
.corporate-success-container{
    text-align: center;
    padding: 80px 30px;
    .corporate-success-page-header{
        font-family: $opensans;
        margin-top: 20px;
        font-size: 22px;
        font-weight: 700;
        color:$black;
    }
    .corporate-success-confirmation{
        font-family: $opensans;
        font-size: 16px;
        font-weight: 400;
        color:$black;
        margin-bottom: 60px;
    }
}
.employees-select{
    background-color: $white;
    border: none;
    border-radius: 0px !important;
    height: 32px;
}
@media screen and (max-width:1150px){
	.gifting-banner-container{
		
		.gifting-banner-text{
				width: 85%;
		}
	}
}
@media screen and (max-width:768px){
	.gifting-text-container{     
			padding:30px 30px;
			}
	.gifting-banner-container{
			.gifting-banner-text{
					width: 85%;
					.gifting-banner-title{
						font-size: 24px;
					}
					.gifting-banner-subheader{
							font-size: 14px;
					}
					.gifting-banner-subheader2{
							font-size: 12px;
					}
					.quote-name{
						font-size:12px;
						padding-top: 3px;
						padding-bottom: 5px;
					}
			} 
			
    }
    .gifting-text-small{
        font-size: 12px;
    }.circle-container{
        margin: 20px 0 10px 0;
        .circled-number{
            font-size: 14px;
            padding:5px 11px;
        }
    }
	.gifting-heading{
    font-size:28px;
	}
	.gifting-text{
			font-size: 14px;
	}
		.table{
				.table-head{
						font-size: 12px;
				}
				.table-body{
						font-size: 12px;
				}
		}
	.gifting-form-container{
			margin: 30px;
			padding: 0 15px 15px 15px;
	}
	.submit-btn{
			margin-right: 0;
	}
	.corporate-gifting-heading{
			font-size: 22px;

	}
}


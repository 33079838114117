@import './variables.scss';

.upsell-modal-container{
  padding: 40px 30px;
  .upsell-title{
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-family: $playfair;
  }
  .upsell-subtitle{
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: $opensans;
  }
  .upsell-product-container{
    display: flex;
    margin: 40px 0;
    .upsell-image-container{
      flex: 4;
      img{
        width: 100%;
      }
    }
    .upsell-description-container{
      flex: 3;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .upsell-title-cont{
        display: flex;
        .upsell-item-title{
          flex: 3;
          font-size: 22px;
          font-family: $playfair;
          font-weight: 400;
          padding-right: 20px;
          line-height: 1.2;
        }
        .upsel-price-cont{
          flex: 2;
          text-align: left;
          .upsell-price-title{
            font-size: 12px;
            font-family: $opensans;
            font-weight: 600;
            margin: 5px 0;
          }
          .upsell-price{
            font-family: $opensans;
            .upsell-price-no-more{
              font-size: 18px;
              text-decoration: line-through;
            }
            .upsell-price-offer{
              margin-left: 10px;
              font-size: 24px;
              color: $promo;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
      }
      .upsell-description{
        font-size: 14px;
        max-width: 230px;
      }
      .upsell-quantity-cont{
        display: flex;
        .upsell-qty-text{
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          margin: auto 10px auto 0;
        }
        .upsell-select-qty{
          border: none;
          background-color: $altbackgroundmiddle;
        }
      }
    }
  }
  .upsell-cta-cont{
    display: flex;
    justify-content: center;
    align-items: baseline;
    .upsell-decline{
      width: 120px;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
    }
    .upsell-accept{
      width: 340px;
      border: none;
      height: 47px;
      font-size: 14px;
      color: $white;
      background-color: $cta;
      border-radius: 3px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .upsell-modal-container{
    padding: 20px 0;
    .upsell-title{
      width: 80vw;
      margin: 0 auto 5px;
    }
    .upsell-product-container{
      flex-direction: column;
      margin: 20px 0 0;
    }
    .upsell-cta-cont{
      .upsell-decline{
        width: 150px;
        text-align: center;
      }
      .upsell-accept{
        width: 170px;
      }
    }
  }
}
@import './variables.scss';

.reviewscomponent-container{
  //padding: 30px;
    background-color: $white;
    text-align: center;
    color: $white;
    margin-bottom: 20px;
    .reviewscomponent-card{
        display: flex !important;
        padding: 60px 120px 20px;
        .reviewscomponent-content{
            flex: 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $secondaryGrey;
            .reviewscomponent-stars{
                    width: 100px;
                }
            .reviewscomponent-text{
                font-size: 22px;
                font-family: $opensans;
                line-height: 1.3 !important;
                min-height: 40px;
                max-width: 360px;
                text-align: center;
                margin: 20px;
            }
            .reviewscomponent-customer{
                font-size: 14px;
                font-style: italic;
                font-family: $opensans;
                margin-bottom: 10px;
                font-style: italic;
                
            }
            .more-reviews-btn{
                background-color: $cta;
                color: $white;
                font-family: "Open Sans", sans-serif;
                font-size: 14px;
                width: 180px;
                height: 35px;
                border: none;
                border-radius: 3px;
                line-height: 1;
                &:hover{
                    background-color: $ctaLight;
                }
                &:focus{
                    outline: none;
                }
            }
            //.more-reviews-btn:
        }
        .reviewscomponent-image{
            flex: 3;
            width: 100%;
        }
        .left{
            order: -1;
        }
    }
    .slick-prev, .slick-next {
        background: transparent;
        opacity: 1;
        height: 60px;
        width: 60px;
        padding: 0;     
    }
    .slick-prev{
        left: 25px;
    }
    .slick-next{
        right: 25px;
    }
}
.reviewscomponent-link{
    margin-top: 40px;
    font-weight: 400;
    width: 100%;
    color: $black;
    text-align: center;
    &:hover{
        color: $secondary;
    }
}
@media screen and (max-width:768px){
    .reviewscomponent-container{
        margin-bottom: 5px;
        .reviewscomponent-card{
            flex-direction: column;
            padding: 20px;
            .reviewscomponent-content{
                padding: 30px 20px 0;
                .reviewscomponent-text{
                    font-size: 16px;
                }
            }
            .right{
                order: -1;
            }
        }
        .slick-prev, .slick-next {
            height: 40px;
            width: 30px;
            top: 110vw;
            opacity: 0.5;
        }
        .slick-prev{
            left: 5px;
        }
        .slick-next{
            right: 5px;
        }
    }
}
@media screen and (max-width:415px){
    .reviewscomponent-container .slick-prev, 
    .reviewscomponent-container .slick-next{
        top: 120vw;
    }
}
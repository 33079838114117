@import './variables.scss';


.overlays-container{
  
  .categories-container{
    display: flex;
    .categorie-label{
      margin: 5px 20px 5px 0;
      padding: 8px 0;
      font-size: 14px;
      font-family: $opensans;
      color: $secondaryGrey;
      font-weight: 600;
    }
    .categories-select-container{
      display: flex;
      overflow-x: auto;
      height: 42px;
      width: 100%;
      position: relative;


      .scroll_container {
        overflow-x: auto;
        display: flex;
        position: absolute;
        left: 20px;
        width: calc( 100% - 40px );

        -ms-overflow-style: none;
        scrollbar-width: none;

        @media screen and (max-width:769px){
          left: 0;
          width: 100%;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
      .categorie-button{
        font-size: 14px;
        font-family: $opensans;
        color: $secondaryGrey;
        border: none;
        border-radius: 30pt;
        background-color:#EDEFF2;
        height: 32px;
        min-width: 90px;
        text-transform: capitalize;
        margin: 5px;
      }
      .selected-category{
        color: $white;
        background-color: $secondaryGrey;
      }
      .categorie-button:focus{
        outline:none;
      }
      .categorie-button:hover {
        opacity: 0.66;
      }

      .l-arr.disabled, .r-arr.disabled {
        cursor: pointer;
        pointer-events: none;
        opacity: 0.2;
      }
    }
    .categories-select-container::-webkit-scrollbar {
      display: none;
    }
  }

  .overlay-carousel{
    display: flex;
    // overflow-x: auto;
    overflow: hidden;
    width: 100%;
    height: 70px;
    position: relative;

    .l-arr.disabled, .r-arr.disabled {
      cursor: pointer;
      opacity: 0.2;
      pointer-events: none;
    }

    .scroll_container {
      overflow-x: auto;
      display: flex;
      width: calc(100% - 40px);
      padding: 20px 0; 
      @media screen and (max-width:769px){
        width: 100%;
      }
      
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    .not-selected-overlay{
      opacity: 0.4;
    }
    .overlay-image{
      height: 30px;
      padding: 0 10px;
    }
    .overlay-image:hover {
      opacity: 0.66;
    }
  }
  .overlay-carousel::-webkit-scrollbar {
    display: none;
  }
  .overlay-colors{
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    .colors-cont{
      display: flex;
      .categorie-label{
        margin:2px 30px 5px 0;
        // padding: 8px 0;
        font-size: 14px;
        font-family: $opensans;
        color: $secondaryGrey;
        font-weight: 600;
      }
      .color-select{
        margin-right: 10px;
        height: 20px;
        width: 20px !important;
        max-width: 20px !important;
        border-radius: 10px;
        border: 1px solid #171717;
        background-color: transparent;
      }
      .color-black{
        background-color: #171717;
      }
      .color-select:focus, .remove-overlay:focus{
        outline: none;
      }
      .color-select:hover, .remove-overlay:hover{
        opacity: 0.7;
      }
    }
    .remove-overlay{
      font-size: 14px;
      font-family: $opensans;
      color: $secondaryGrey;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      padding: 0;
      line-height: 0.5;
      margin-left: 20px;
    }
  }

}
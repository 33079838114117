.empty-cart-page {
  background-position: 80% 0;
  background-size: cover;
  width: 100%;
  margin: auto;
  margin-top: -20px;
}

.empty-cart-page__content.container{
  padding-bottom: 100px !important;
  max-width: 468px;
  margin: 0 auto;
  .empty-cart-header{
    margin: 60px 0px;
    font-family: $opensans;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  
  p {
    font-family: $opensans;
    display: block;
    font-size: 26px;
    font-weight: 300;
    padding-bottom: 40px;
    text-align: center;
    line-height: 1.6 !important;
  }
.continue-shopping-cont{
  text-align: center;

  .btn {
    font-size: 16px;
    padding: 10px 10px;
    margin: 10px 0px;
  }
}
}
.empty-cart-title{
  font-family: $playfair;
  font-size: 22px !important;
  font-weight: 400;
  margin-top: 84px !important;
}
.empty-cart-icon-cart{
  margin-right: 7px;
  height: 24px;
  width: 24px;
  float: left;
  margin-bottom: 4px;
}

// @media (max-width: $tablet-width) {
//   .empty-cart-page__content {
//     h2 {
//       font-size: 20px;
//       font-weight: 800;
//     }
//     p {
//       font-size: 16px;
//     }
//   }
// }
@media screen and (max-width:768px){
  .empty-cart-page__content {
    padding: 0 20px 60px !important;
    .empty-cart-header{
      font-size: 25px !important;
    }
    .empty-cart-title{
      margin-top: 30px !important;
    }
    h2 {
      margin: 24px 0px;
      font-size: 48px;
    }
  
    p {
      display: block;
      font-size: 20px !important;
      font-weight: 300;
      padding-bottom: 30px;
    }
  }
}
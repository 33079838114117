@import './variables.scss';


  .image-warning-message:hover {
    cursor: pointer;
  }
  .image-warning-message{
    color: $failure;
    margin-bottom: 20px;
    .warning-img{
      height: 13px;
      margin-top: -3px;
    }
    .link-learn-more{
      text-decoration: underline;
    }
  }
  .image-good-message{
    color: $success;
    margin-bottom: 10px;
    .thumb-img{
      height: 13px;
      margin-top: -3px;
    }
    
  }
  .main-container-warning{
    height: auto;
    padding-bottom: 70px;
    .warning-modal-container{
      margin: 20px 40px;
      bottom: auto;
      transform: translate(0, 15%);
      .warning-modal-title-cont{
        text-align: center;
        margin-bottom: 40px;
        .warning-modal-title{
          font-size: 24px;
          font-family: $opensans;
          color:$black;
          font-weight: 600;
          line-height: 1.5 !important;
        }
      }
      .warning-modal-cont{
        text-align: center;
        font-family: $opensans;
        .column{
          padding: 10px;
        }
        .warning-modal-icon{
          width: 45px;
          margin-bottom: 20px;
        }
        .warning-modal-text{
          font-size: 16px;
        }
        .warning-modal-text-small{
          font-size: 14px;
        }
      }
    } 
  }
.ant-modal-content{
  border-radius: 0 !important;
}
@media (max-width: 768px) {
  .main-container-warning{
    height: auto;
    padding-bottom: 10px;
    .warning-modal-container{
      transform: translate(0, 0) !important;
      .warning-modal-title{
        padding: 40px 0 0;
      }
      .warning-modal-cont{
      flex-wrap: wrap;
      .column{
        width: 100%;
      }
      }
    }
  }
}
@media (min-width: 480px) {
  .usp-image1{
    display: none;
  }
}
@media (max-width: 480px) {
  .usp-image3{
    display: none;
  }
}
@import './variables.scss';

.services-main-container{
  padding-bottom: 60px;
  padding-top: 80px;
  .services-banner-container{
    text-align: center;
    .services-title-container-mobile{
      position: relative;
      .services-title1{
        text-align: left;
        position: absolute;
        .services-banner-title{
          padding: 20% 20px 0;
          line-height: 1.5 !important;
          font-family: $opensans;
          font-size: 32px;
          font-weight: 400;
          letter-spacing: 6px;
          text-transform: uppercase;
        }
        .services-banner-text{
          padding: 40px 20px 0;
          line-height: 1.5 !important;
          font-family: $opensans;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .services-title-container-desktop{
      .services-title2{
        text-align: center;
        .services-banner-title{
          padding: 20px 20% 0;
          line-height: 1.5 !important;
          font-family: $opensans;
          font-size: 28px;
          font-weight: 400;
          letter-spacing: 6px;
          text-transform: uppercase;
        }
        .services-banner-text{
          padding: 20px 20%;
          line-height: 1.5 !important;
          font-family: $opensans;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    
    
    .services-icon{
        height: 30px;
        margin-bottom: 10px;
    } 
    .services-heading{
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2 !important;
    }
    .services-subheading{
      font-size: 14px;
      line-height: 1.2 !important;
    }
    .services-line{
      border-top: 2px solid $grey;
      margin: 0 20%;
      }
    .services-footer{
      margin-bottom: 20px;
      margin-top: 15px;
      font-size: 16px;
      line-height: 1.2 !important;
    }
  }
}  

  @media screen and (max-width: 1089px){
    .columns-services-mobile{
      padding: 30px;
    }
  }
  @media(min-width:769px) and (max-width:1087px){
    .services-main-container{
      .services-banner-container{
        .services-title-container-mobile{
          .services-title1{    
            .services-banner-title{
              padding: 20px 30px;
              font-size: 26px;
            }
            .services-banner-text{
              padding-left: 30px;
              font-size: 16px;
              padding-top: 10px;
              padding-right: 30%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px){
    .services-main-container{
      padding-top: 30px;
    }
  }
  
  @media screen and (max-width: 400px){
    .services-main-container{
      .services-banner-container{
        .services-title-container-mobile{
          .services-title1{    
            .services-banner-title{
              padding:20px 20px;
              font-size: 26px;
            }
            .services-banner-text{
              font-size: 16px;
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px){
    .services-main-container{
      .services-banner-container{
        .services-title-container-mobile{
          .services-title1{    
            .services-banner-title{
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  
@import '../../assets/stylesheets/variables.scss';

.image__manager {
  padding-top: 144px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.image__manager__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 14px;
  box-sizing: border-box;
}

.image__manager__btns__holder {
  overflow: hidden;
  padding: 0;

  .btn {
    margin-right: 20px;
  }
}

.image__manager__list__holder {
  width: 100%;
  height: calc(100% - 20px);
  overflow: auto;
}

.image__manager__list {
  overflow: hidden;
  text-align: center;
}

.image__manager__list__item {
  width: 120px;
  height: 120px;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
}

.image__manager__list__image {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;

  &.selected {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      display: block;
    }

    &:before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      z-index: 2;
      color: $white;
      font-size: 30px;
    }

    &:after {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.5);
      z-index: 1;
    }
  }
}

.image__manager.simple__selection {
  position: static;
  padding: 0;

  .image__manager__list {
    text-align: left;
  }

  .image__manager__list__item {
    width: 110px;
    height: 110px;
    padding: 0 10px 10px 0;
  }

  .image__manager__list__holder {
    max-height: 370px;
    overflow: auto;
  }
}
.tip{
  margin-bottom: 30px;
}

//// modal 
.modal-close-btn{
  background-color: transparent;
  border: none;
  color: $secondary;
  position: sticky;
  top: 0;
  float:right;
  width: 38px;
  z-index: 4;
  padding-top: 20px;
}
.modal-close-btn:focus {
  outline:none;
}

.ReactModal__Overlay{
  background: $modalOverlay;
  z-index: 1000;
}

.ReactModal__Content{
  background-color: $altbackground !important;
  border-radius: 1px !important;
  border: none !important;
  //padding:30px !important;
  max-height: 750px;
  //overflow: hidden !important;
  padding-top: 0 !important;
}

@media (min-width: 768px) {
  .ReactModal__Content{
    width: 500px;
  }
}

@media (max-width: 768px) {
  .ReactModal__Content{
    .modal-close-btn{
      width: 45px;
    }
  }
}


@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Lato:500,600,700");
body {
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth; }

html {
  height: 100%; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

section.main__container {
  padding-top: 20px; }

button, .btn, a, select {
  cursor: pointer !important; }

@import './variables.scss';

.cart-item-wrapper{
  max-width: 468px;
  padding: 10px;
  background: $white;
  border-bottom: 1px solid $grey;
  .card-item-main-container{
    display: flex;
    padding-bottom: 10px;
    .card-item-image-container{
      flex: 1;
    }
    .card-item-content-container{
      flex:2;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-family: $opensans;
      padding-left: 10px;
      .card-item-name{
        font-family: $playfair;
        font-size: 14px;
        color: $black;
        margin-bottom: 0;
      }
      .price-added-container{
        display: flex;
        align-items: baseline;
        .cart-item-price{
          font-size: 13px;
          font-weight: 700;
          color: $midgrey;
          margin-bottom: 0;
          padding-bottom: 2px;
          strike{
            font-weight: 100;
            color: $cta;
          }
        }
        .added-container{
          padding-left: 10px;
          .added-icon{
            height: 15px;
            margin-right: 7px;
            margin-bottom: 5px;
          }
          .added-text{
            display: inline-block;
            font-family: $opensans;
            font-size: 14px;
            font-weight: 700;
            color:$success;
            margin-bottom: 0;
          }
        }
      }
      .cart-item-ctas{
        display: flex;
        .cart-item-remove, .cart-item-applied-btn{
          padding-left: 10px !important;
          height: 30px;
          border: none;
          border-radius: 2px;
          font-size: 12px;
          margin-left: 5px;
        }
        .cart-item-remove:focus, .cart-item-applied-btn:focus{
          outline:none;
        }
        .cart-item-applied-btn{
          pointer-events: none;
        }
        .cart-item-remove, .cart-item-applied-btn{
          background: $altbackground;
          color: $secondary;
        }
        .cart-item-applied-btn{
          font-size: 10px;
          .check-doubled-icon{
            height: 14px;
            margin-right: 3px;
            margin-top: -1px;
          }
        }
      }
    }
  }
  .cart-item-double-up-btn{
    color: $secondary;
    background: $white;
    border: solid $promo 1px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 13px !important;
    width: 100%;
    -webkit-box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);
    -moz-box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);
    box-shadow: 3px 8px 5px -8px rgba(212,212,212,1);    
    &:hover{
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    &:focus{
      outline:none;
    }
    .info-icon{
      height: 14px;
      margin-bottom: 3px;
      margin-left: 5px;
    }
  }
}
.deleted-from-cart-container{
  text-align: center;
  width: 468px;
  height: 124px;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  .deleted-item-text{
    font-family: $opensans;
    font-size: 20px;
    font-weight: 600;
    padding-top: 50px;
    color: $altbackgrounddark;
  }
}
//ipnones Plus and smaller
@media screen and (max-width: 414px){
  .cart-item-double-up-btn{
    font-size: 9px !important;
  }
}
// iphone 5
@media screen and (max-width: 345px){
  .cart-item-double-up-btn{
    font-size: 9px !important;
  }
}
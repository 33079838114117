@import './variables.scss';

.address__list {
  &:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

.full_width{
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.half_width {
  width: 50%;

  &:nth-child(odd) {
    padding-right: 10px;
    clear: both;
  }

  &:nth-child(even) {
    padding-left: 10px;
  }

}

.address__item {
  float: left;
  box-sizing: border-box;
  padding-bottom: 10px;

  .Select {
    margin-bottom: 0;
  }
}
.autocomplete__search__container{
  padding-left: 0px !important;
}
.address_part {
  padding-right: 30px !important;
  padding-left: 0 !important;
  display: inline-grid;

  &:last-child {
    padding-right: 0 !important;
  }

}

.autocomplete__item {
  &:nth-child(odd) {
    padding-left: 10px;
    padding-right: 0px;
    clear: none;
  }

  &:nth-child(even) {
    padding-right: 10px;
    padding-left: 0px;
    clear: both;
  }
}

.autocomplete__search__container.autocomplete__item {
  position: relative;
  width: 100%;
  padding-right: 0;
}

.billing__container,
.address__holder {
  padding-top: 0px;
  &-close {
    max-height: 0;
    overflow: hidden;
  }
}

.select-state{
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none !important;
  border-radius: 0px;
  border: none;
  font-family: $opensans;
  color: $midgrey;
  height: 30px !important;
  line-height: 2;
  padding-left: 10px;
  background: url('https://storage.googleapis.com/myphoto-img/icons/arrows-up-and-down.png') no-repeat;
  background-position: right center;
  background-color: $white;
}
.select-state:focus{
  outline: none;
}

.zip-input{
  width: 110px;
}

// @media (max-width: $tablet-width) {
//   .address__item {
//     float: none;
//     width: 100%;

//     &:nth-child(odd),
//     &:nth-child(even) {
//       padding-left: 0px;
//       padding-right: 0px;
//     }
//   }

// }

.suggestion-btn{
  text-align: center;
  font-family: $opensans;
  color:$secondary;
  text-decoration: underline;
  float: right;
  max-height: 32;
  cursor: pointer;
}
@media (min-width: 576px) {
  .suggestion-button-mobile{
    display: none;  
  }
}
@media (max-width: 576px) {
  .suggestion-button-desktop{
    display: none; 
  }
}
@import './variables.scss';

.address-verification-container{
    font-family: $opensans;
    padding: 40px;
    .address-verification-heading{
        margin-bottom: 40px;
        .danger-sign{
            width: 30px;
            margin-right: 20px;
            margin-bottom: 5px;
        }
        .address-verification-heading{
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            color: $black;
        }
    }
    
    .address-suggestion-container{
        margin-bottom: 60px;
        text-align: center;
        .address-heading{
            font-weight: 600;
            font-size: 18px;
            margin-top: 52px;
            text-transform: capitalize;
        }
        .ant-radio {
            display: block;
            margin-right: 0;
            position: absolute;
            top:60px;

        }
        .ant-radio-wrapper{
            background-color:$white;
            padding:   0 20px;
            width: 310px;
        }
    }
    .address-buttons-container{
        justify-content: space-around;
        flex-direction: column;
        .back-button{
            margin-bottom: 15px;
            font-size: 18px !important;
            text-align: center;
            padding: 9px 22px;
            font-family: $opensans;
            border: none;
            text-decoration: underline;
            background-color: transparent;
        }
        .place-order{
            text-align: center;
            .place-order-btn{

            }

        }
    }
}

@media screen and (max-width:768px){
   .address-verification-container{
       padding:20px;
       .address-verification-heading{
        margin-bottom: 20px;
        .danger-sign{
            width: 30px;
            margin-right: 20px;
        }
        .address-verification-heading{
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }
    }
    .address-suggestion-container{
        text-align: center;
        .address-heading{
            font-size: 16px !important;
            text-transform: capitalize;
        }
        .columns{
            flex-wrap: wrap;
        }
        .column{
            text-align: center !important;
            width: 100%;
            padding: 0;
        }
        margin-bottom: 20px;
        .address-heading{
            font-weight: 600;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 10px;
            
        }
    }
    .address-buttons-container{
        flex-wrap: wrap;
        .back-button{
            font-size: 16px !important;
            height: 42px;
            margin-bottom: 10px;
        }
    }
   }
}
@import './variables.scss';

p{
    line-height: 18px !important;
}
.waren-container{
    width: 100%;
    background-color: $secondary;
    display: flex;
    min-height: 280px;
    .image-warren{
        padding-top: 20px;
        flex:3;
        position: relative;
        img{
            height: 260px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .our-story-quote-container{
        flex: 5;
        position: relative;
        img{
            height: 30px;
            position: absolute;
            left: -30px;
            top: 40px;
        }
        p{
            margin: 40px 30% 40px 20px;
            font-family: $playfair;
            font-size: 24px;
            color: $white;
            line-height: 2 !important;
        }
    }
}
.our-story-text-container{
    background-color: $white !important;
    padding: 60px 20%;
    p{
        line-height: 2 !important;
        font-family: $opensans;
        font-size: 16px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width:769px){
    .waren-container{
        flex-direction: column;
        background-color: $white;
        .image-warren{
            position: unset;
            text-align: center;
            background-color: $secondary;
            img{
                position: unset;
            }
        }
        .our-story-quote-container{
            img{
                left: 20px;
            }
            p{
                margin: 40px 40px 0px 70px;
                color: $black;
                font-size: 20px;
            }
        }
    }
    .our-story-text-container{
        background-color: $white !important;
        padding: 20px 60px 10px;
    }
}
@import './variables.scss';

.checkout__table__holder {
  padding: 30px 0 10px 0;
}

.checkout__table {
  overflow: hidden;
  font-size: 14px;
  font-family: $opensans;
  li {
    float: left;
    box-sizing: border-box;
    line-height: 20px;
    padding: 5px 0;

    &:nth-child(1) {
      width: 60%;
      padding-left: 10px;
    }

    &:nth-child(2) {
      width: 20%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 20%;
      text-align: right;
      padding-right: 10px;
    }
  }
}

.checkout__table__header {
  border-bottom: 1px solid $grey;
  text-transform: uppercase;
  font-size: 12px;
  font-family: $opensans;
  .is-4, .is-2{
    text-align: right;
  }
  .is-6{
    text-align: left;
  }
}
.item_details{
  .is-4, .is-2{
    text-align: right;
  }
  .is-6{
    text-align: left;
  }
}
.checkout__table__total {
  border-top: 1px solid $grey;
  padding-top: 10px;
}


// @media (max-width: $tablet-width) {
//   .checkout__table {
//     li {
//       &:nth-child(1) {
//         width: 55%;
//       }

//       &:nth-child(3) {
//         width: 25%;
//       }
//     }
//   }
// }

// @media (max-width: $mobile-width) {
//   .checkout__table__header {
//     font-size: 12px;
//   }
// }

.ant-form-item{
  margin-bottom: 5px !important;
}
@import '~antd/dist/antd.css';
@import '../../assets/stylesheets/variables.scss';

// Definition of colors


.ribbon-menu-container{
  position: sticky;
  z-index: 101;
}
.navbar__container {
  z-index: 102;
  height: 60px;
  position: sticky; 
  top: 0;
  top: 40;
  left: 0;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $altbackgrounddark;
  .main__container{
    .header-container{
      display: flex;
      justify-content: space-between;
      .navbar_brand{
        width: 200px; // 150
        height: 60px;
        display: flex;
        align-items: center;
        padding-left:20px;
        margin-left: 15px;
        padding-right: 35px;
        .navbar-logo {
          display: block;
          width: 150px;
          height: 48px;
          text-indent: -999999px;
          background-position: left;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .navbar__list{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        .links{
          white-space: nowrap;
        }
        .header-item{
          text-align: center; 
          margin-right: 0.75rem;
          .gift-guide-icon{
            height: 16px;
            margin-right: 5px !important;
            margin-bottom: 3px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
#hero__img {
  width: 100%;
}


.navbar__item {
  float: left;
  padding: 0 10px;

  &:last-child {
    padding-right: 0;
  }
}
/// CART BADGE + ANIMATION
.icon-cart {
  position: relative;

  .cart-badge {
    position: absolute;
    top: 2px;
    left: 11px;
    .cart-badge-text{
      letter-spacing: -1.5px !important;
      font-size: 11px;
      color: $secondaryGrey;
    }
    &.in {
      animation-name: badgeIn;
      animation-duration: 1.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
    }

    &.out {
      animation-name: badgeOut;
      animation-duration: 0.5s;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
    }
  }
}

.invisible {
  display: none !important;
}

@keyframes badgeIn {
    0% {
      opacity: 0;
      width: 5px;
      height: 5px;
      font-size: 3px;
      line-height: 5px;
      top: 11px;
      right: 11px;
    }
    25% {
      opacity: 1;
      width: 26px;
      height: 26px;
      font-size: 17px;
      line-height: 26px;
      top: 1px;
      right: 2px;
    }
    50% {
      width: 26px;
      height: 26px;
      font-size: 17px;
      line-height: 26px;
      top: 1px;
      right: 2px;
    }
    100% {
      top: -7px;
      right: -4px;
      width: 17px;
      height: 17px;
      line-height: 17px;
      font-size: 12px;
    }
}

@keyframes badgeOut {
    0% {
      top: -7px;
      right: -4px;
      transform: translate(0, 0);
      width: 17px;
      height: 17px;
      line-height: 17px;
      font-size: 12px;
    }
    100% {
      opacity: 0;
      width: 5px;
      height: 5px;
      font-size: 3px;
      line-height: 5px;
      top: 11px;
      right: 11px;
    }
}

//new
.upload-cart-group{
  align-content: center;
  padding-bottom: 0;
}

.button:focus, .button:active, .button:hover {
  outline:none !important;
  border: none !important;
  text-decoration: none !important;
  outline:0 !important;
  box-shadow: none !important;
}

.navbar-icons{
  display: flex;
}
.navbar-item{
  padding-bottom: 0;
}

// header right CTAs
.cart-container{
  display: flex;
  justify-content: space-around;
  height: 60px;
  width: 220px;
  align-items: center;
  margin-right: 30px;
  .help-icon{
    height: 20px;
    width: auto;
  }
  .cart-icon{
    cursor: pointer;
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 15px;
  }
  .cart-link-text{
    display: inline-block;
    font-size: 14px;
    color: $white;
    font-family: $opensans;
    line-height: 100%;
    font-weight: 600;
    padding-left: 4px;
    margin:0;
  }
// **** ADD MYPHOTO btn:
.add-btn{
  background-color: $cta;
  border-radius: 0;
  //padding: 8px 20px;
  border: none;
  border-radius: 3px;
  width: 122px;
  height: 40px;;
  .upload-icon{
    height: 9px;
    margin-right: 5px;
  }
  .upload-link-text{
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: $white;
    font-family: $opensans;
    line-height: 100%;
    padding-left: 4px;
    margin:0;
  }
}
.add-btn:hover{
  background-color: $ctaLight;
}
.add-btn:focus{
  outline:none;
}
}


.cart-link-text{
  margin-bottom: 5px;
}
a:-webkit-any-link{
  text-decoration:none !important;
  }
  
.about-us-link-mobile{
  height: 30px;
  width: 30px;
  margin-bottom: 14px;
}
.links{
  color: $secondaryGrey;
  font-family: $opensans;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  line-height: 2;
  margin: 0 20px;
  cursor: pointer;
}
.links:hover {
  color: $cta;
}
.active{
  color: $cta;
}
.links:active,.links:focus,.links:hover {
  text-decoration: none !important;
}
//////////// BURGER MENU *******************
.ribbon-menu-container{
  top:0px;
  .promo-ribbon-inapp{
   position:fixed;
   top:0px;
  }
  .countdown-container{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
.bm-burger-button{
  width: 16px;
  position: absolute;
  right: 35px;
  top:17px;
  //padding-top:90px;
 }

// ******* Drop Down Menu *********:

.ant-dropdown{
  z-index: 100;
}

.dropdown-content{
  background-color: $white;
  border: 1px solid $grey;
  padding: 30px 50px 30px 100px;
  display: flex;
  .first-column, .second-column{
    display: flex;
    flex-direction: column;
    .dropdown-title{
      color: $secondaryGrey;
      font-family: $opensans;
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 6px;
      border-bottom: 1px solid #d7d7d7;
      width: max-content;
    }
    .frames-canvas-link, .table-top-link{
      margin-bottom: 18px;
    }
  }
  .dropdown-menu-link{
    color: $secondaryGrey;
    font-family: $opensans;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    line-height: 1.8;
    margin-right: 50px;
    cursor: pointer;
  }
  .shop-all-link{
    margin-bottom: 20px;
  }
  .dropdown-menu-link:hover {
    color: $cta;
  }
  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
    background-color: $white !important;
  }
  .second-column-shopall{
    margin-left: 100px;
    margin-right: 50px;
  }
}
.hamburger-container{
  // changing ANTD collapse styling only for hamburger menu:
  .ant-collapse{
    margin-bottom: 0 !important;
    border: none;
  }
  .ant-collapse-header{
    background-color: $white;
  
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: white;
  }
  .ant-collapse-arrow{
    height: 10px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 6px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 6px 7px;
    padding-left: 20px;
    font-family: $opensans;
    color: $secondary;
    outline: 4px solid white !important;
    background-color: white;
  }
  
  .ant-collapse-content > .ant-collapse-content-box{
    padding: 0;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    .collapse-link{
      font-family: $opensans;
      color: $secondary;
      padding: 4px 5px;
    }
    .collapse-link:active{
      color: $cta;
    }
  }
  .ant-collapse-content-active{
    border: 1px solid white;
  }

  .extra_pad {
    display: none;
  }
}
//******* HAMBURGER MENU icon *********:

#nav-icon3 span{
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: $secondaryGrey;
  border-radius: 2px;
  opacity: 1;
  left: 0;
}
/* Icon */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2) {
  top: 6px;
}
#nav-icon3 span:nth-child(3){
  top: 12px;
}

 .bm-menu-wrap{
  position: relative;
  top: 0;
 }
 .bm-menu {
   position: absolute;
   right: 0;
   left: 0;
   //top: 93px;
   background: $white;
   padding: 2.5em 1.5em 0;
   font-size: 1.15em;
 }
  .bm-overlay{
    ///Uncoment out for a transparent menu Overlay 
   background: $modalOverlay !important;
    top:0;
  }
.menu-links-cont:focus{
  outline: none;
}
 .menu-link{
   color: $secondary;
   font-family: $opensans;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 1px;
   line-height: 3;
   cursor: pointer;
   display: block;
 }
.home-link, .shopall-link, .faq-link-hm, .order-status-link{
  padding-left: 20px;
  text-transform: uppercase;
}
 .menu-link:active,.menu-link:focus,.menu-link:hover {
   text-decoration: none !important;
 }
 .menu-link:hover {
   color: $cta;
 }
 .bm-icon{
   margin-bottom: 15px;
 }
 .bm-burger-button:focus {
  outline: none !important; // not working ???
}
 .empty-menu-div{
   background-color: transparent;
   height: 30px;
   width: 45px;
   margin-left: 10px;
 }
.bm-cross-button{
  width: 20px !important;
  height: 20px !important;
  right: 16px !important;
  top: 16px !important;
}

 // end of hamburger menu
 .tv-mobile-container{
  background-color: $midgrey;
  height: auto;
  text-align: left;
  margin-bottom: -5px !important;

  .tv-logo-img-mobile{
    max-width: 100%;
    height: auto;
    max-height: 40px;
    margin: 7px 0px 7px 30px;
  }
  .tv-ribbon-text{
    color: white;
    text-align: center;
    padding-top: 8px !important;
    font-family: $opensans;
    font-size: 14px;
    margin-left: -15px !important;
  }
}
/// chatBot:
#custom_fc_button{
  cursor: pointer;
}
#imageicon{
  height: 20px;
}
/////////////MObILE && Touch///////////

@media screen and (max-width: 1093px){
  .dropdown-content{
    padding: 30px 30px 30px 60px;
    .dropdown-menu-link{
      margin-right: 30px;
      font-size: 13px;
    }
  }
  #hero__img {
    height: auto;
  }
   .navbar_brand{
    justify-content: left;
  }
  .navbar-logo{
    max-width: 150px;
  }
  .upload-cart-group{
    align-content: center;
  }
  .navbar__container .main__container .header-container .navbar__list{
    margin-right: 0;
  }
  .links{
    margin: 0 10px;
  }
  .cart-container{
    margin-right: 10px;
  }
}
@media screen and (min-width: 769px){
  .bm-burger-button{ // hiding hamburger menu on desktop
    display: none;
  }
  .hamburger-container{
    display: none;
  }
}
@media screen and (max-width: 768px){
  .navbar__container {
    height: 80px;
    .main__container .header-container{
      .hamburger-container{
        width: 33%;
        .bm-burger-button {
          left: 16px;
          top:17px;
        }

      }
      .navbar_brand{
        width: 33%;
        padding: 0;
        margin: 0;
        height: 50px;
      }
      .navbar__list {
        width: 100%;
      }
      .cart-container{
        width: 33%;
        justify-content: flex-end;
        height: 50px;
        #open_fc_widget{
          margin-right: 15px;
        }
      }
    }
  }

  .ribbon-menu-container{
    position: relative;
  }
 
  .add-btn{
    top: 0px;
    height: 48px;
    width: 48px;
    .upload-link-text{
      display: none;
    }
  }
  .cart-container{
    width: 60px;
    .cart-icon{
      height: 19px;
      width: 19px;
      float: right;
    }
  }
  .navbar_brand{
    justify-content: center;
    height: 60px;
    
  }
  .navbar-logo{
    height: 40px;
    max-width: 140px;
    background-position: center;
  }
  #custom_fc_button{
    bottom: 20vh;
  }
  .navbar__list{
    position: absolute;
    bottom: -10px;
    .links{
      font-size: 12px;
  
    }
  }
}
@media screen and (max-width: 768px) and (orientation: landscape){
  .hamburger-container .extra_pad {
    display: block;
    height: 150px;
  }
}
// end of mobile

/////////////TV Ribbon/////////////////:

@media screen and (max-width: 370px){
  .navbar_brand {
    width: 130px; 
  }
  .navbar-logo{
    max-width: 130px;
  }
}
@import './variables.scss';

.added-to-cart-container{
  background: $altbackground;
  padding-top: 40px;
}

.added-main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.added-total-list{
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-family: $opensans;
  font-weight: 600;
  align-items: center;
  margin-top: 7px;
  .added-shipping, .added-subtotal-text{
      color: $black;
      font-size: 14px;
  }
  .added-shipping-type{
      flex:1;
      color: $cta;
  }
}
.added-continue-order{
  text-align: center;
  .added-continueshopping-link{
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 16px;
      font-family: $opensans;
      color: $cta;
      font-weight: 700;
      letter-spacing: 1.5px;
      line-height: 1.5;
      cursor: pointer;
  }
}
.added-ctas{
  display: flex;
  align-items: flex-end;
  .added-go-to-cart-btn{
    flex: 1;
    background-color: $secondary;
    text-align: center;
    border:none;
    height: 52px;
    padding:0 30px;
    .added-go-to-cart-icon{
        margin-top: -3px;
        height: 16px;
        margin-right: 10px;
    }
    .added-go-to-cart-text{
        display: inline-block;
        margin: 17px 0;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: $white;
        font-family: $opensans;
    }
  }
  .added-checkout-btn{
    flex: 1;
    background-color: $cta;
    color: $white;
    font-family: $opensans;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border:none;
    display: block;
    height: 52px;
    padding:0 30px;
    margin-bottom: 0;
  }
}
.shipping-progress-container{
  margin-bottom: 20px;
  max-width: 468px;
  width: 100%;
  margin-top: 15px;
  padding: 12px 20px;
  background: $white;
  .progress-sub-container{
    flex-grow: 1;
    .progress-text{
      font-family: $opensans;
      text-align: center;
      color: $black;
      p{
        font-size: 13px;
        margin-bottom: 6px;
      }
      b{
        font-weight: 600;
      }
    }
    .ant-progress-inner{
      height: 15px;
      border-radius: 4px;
    }
    .ant-progress-bg{
      background-color: $instruction;
      height: 15px !important;
      border-radius: 4px !important;
    }
    .ant-progress-status-success .ant-progress-bg{
      background-color: $instruction;
    }
  }
}
.added-total-list{
  max-width: 468px;
  display: flex;
  justify-content: space-between;
  font-family: $opensans;
  font-weight: 600;
  align-items: center;
  padding: 15px 10px;
  .added-shipping, .added-subtotal-text{
      color: $black;
      font-size: 14px;
  }
  .added-shipping-type{
      flex:1;
      color: $cta;
  }
}
@media screen and (min-width:768px){

  .added-ctas{
    padding: 0 15%;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    .added-go-to-cart-btn{
      flex: none;
    }
    .added-checkout-btn{
      flex: none;
    }
  }
}
@media screen and (max-width:768px){
  .added-continue-order{
    padding-left: 10px !important;
  }
}
@import '../variables.scss';

.multi__photo {
  padding-top: 50px;
}

.multi__photo__buttons__container {
  padding: 30px 0;

  .btn {
    margin-right: 10px;
  }
}

.multi__photo__list,
.multi__photo__buttons__list {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  list-style: none;
}

.multi__photo__item {
  padding-bottom: 30px;
  position: relative;
  float: left;
  width: 50%;
  box-sizing: border-box;

  &:nth-child(odd) {
    clear: both;
    padding-right: 15px;

    .multi__photo__buttons__list {
      right: 15px;
    }
  }

  &:nth-child(even) {
    padding-left: 15px;
  }

  .product_image {
    width: 100%;
  }
}

.multi__photo__buttons__list {
  width: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 0 30px 0;

  .multi__photo__buttons__item {
    float: left;
    padding: 0 15px 15px 0;
  }
}

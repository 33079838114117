.xcomponent-outlet {
  width: 100% !important;
  margin-bottom: 13px;
  z-index: 0;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
    /* border-radius: 4px; */
    background: white;
  }
  
  /* .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  } */
  
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .InputElement{
    font-family: 'Open Sans', sans-serif !important;
  }

  .in-summary input {
    border: 1px solid #e4e4e4 !important;
  }
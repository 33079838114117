// BREAKPOINTS
$desktop-width: 1150px;
$tablet-width: 768px;
$mobile-width: 500px;

// PADDINGS
$default-padding: 40px;
$tablet-padding: 20px;
$mobile-padding: 10px;

// NEW PALETTE
$cta: #ee6f6f;
$ctaLight:#ff9081;
$information: #d4b697; // Changed to Tan d4b697
$informationLight:#e3c8ac;
$instruction: #6fa8ee;
$instructionLight: #a6ceff;
$linkBlue: #447EB7;
$success: #679f16; // #3d7cc2;
$promo: #e45b2e;
$white: #fff;
$black: #272727;
$altbackground: #f4f4f4;
$altbackgroundmiddle: #f2f2f2;
$altbackgrounddark: #e6e6e6;
$modalOverlay:  rgba(133, 132, 132, 0.7) !important;
$secondary: #686868; ///#323c46;
$secondaryGrey: #323c46;
$secondaryLight: #d7d7d7;
$secondaryDark: #494949;
$secondaryDarkBlue: #227484;
$primaryLight: #f4b1b0; /// maybe #f4b1b0
$failure: #b00202;

$midgrey: #999999; //change name?
$grey: #e6e6e6; //change name?

//barintree
$btValidGreen: #0ac600;
$btInvalidRed: #ed574a;

//SOCIAL MEDIA
$facebook: #3b5998;
$facebookLight: #9baed6;
$instagram: #8a3ab9;
$instagramLight: #ce9ceb;
$google: #d34836; 
$googleLight: #e6afa7;
$mediaHover: #e6e6e6;

//COMPUTER
$computer:#2181d3;
$computerLight: #b7d0e9;

// FONTS
$icons: 'icons';
$opensans: 'Open Sans', sans-serif;
$playfair : 'Playfair Display', serif;

@import 'node_modules/react-modal-video/scss/modal-video.scss';


